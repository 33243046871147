import { schema } from 'normalizr';

// Calendar
export const calendarSchema = new schema.Entity(
  'calendars',
  {},
  {
    idAttribute: 'calendarId',
  },
);

// Event
export const eventSchema = new schema.Entity(
  'events',
  {},
  {
    idAttribute: '_id',
  },
);
