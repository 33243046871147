import { DatesType } from '../../propTypes';
import { __ } from '../../utilities';
import { connect } from 'react-redux';
import { currentEventIdSelector } from '../EventDetail/selectors';
import { openEventDetail } from '../App/actions';
import { rem } from 'polished';
import Event from '../../components/Event';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledRow = styled(Row)`
  position: sticky;
  top: 0;
`;

const Scrollable = styled.div`
  overflow: auto;
  flex: 1 0 0;
`;

const DAY_WIDTH = `${100 / 7}%`;
const DAY_HEIGHT = rem(190);
const Day = styled.div`
  min-width: ${DAY_WIDTH};
  max-width: ${DAY_WIDTH};
  min-height: ${DAY_HEIGHT};
  max-height: ${DAY_HEIGHT};
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  flex-direction: column;
`;

const ColumnHeader = styled.div`
  font-size: ${rem(14)};
  padding: ${rem(15)} ${rem(14)};
  min-width: ${DAY_WIDTH};
  max-width: ${DAY_WIDTH};
  background: ${({ theme }) => theme.monthViewHeaderBackgroundColor};
  text-transform: capitalize;
  border-right: 1px solid ${({ theme }) => theme.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

const DayTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${rem(5)};
`;

const DayTitle = styled.div`
  font-size: ${rem(14)};
  border-radius: ${rem(50)};
  padding: ${rem(9)};
  ${({ selected }) =>
    selected &&
    css`
      background ${({ theme }) => theme.color.primary};
      color: #fff;
    `};
`;

const TodayLabel = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(12)};
  margin-left: ${rem(5)};
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0 ${rem(5)};
  flex: 1 0 0;
`;

const StyledEvent = styled(Event)`
  margin-bottom: ${rem(2)};
  ${({ isFullScreen }) =>
    isFullScreen
      ? css`
          padding: ${rem(10)};
        `
      : css`
          padding: ${rem(5)};
        `};
`;

class MonthView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClickEvent = (id) => {
    this.props.dispatch(openEventDetail(id));
  };

  render() {
    const { events: dates, currentEventId } = this.props;
    const now = moment();

    const dateKeys = Object.keys(dates);
    const columnTitles = [];
    for (let i = 0; i < 7; i++) {
      columnTitles.push(moment(dateKeys[i]).format('dddd'));
    }

    return (
      <Wrapper>
        <Scrollable>
          <StyledRow>
            {columnTitles.map((title, idx) => (
              <ColumnHeader key={idx.toString()}>{title}</ColumnHeader>
            ))}
          </StyledRow>
          <Row>
            {dateKeys.map((date) => {
              const day = moment(date);
              const events = dates[date];
              const isToday = day.isSame(now, 'day');
              return (
                <Day key={date}>
                  <DayTitleWrapper>
                    <DayTitle selected={isToday}>
                      {day.format('D')}
                      {day.format('D') === '1' && `.${day.format('MMM')}`}
                    </DayTitle>
                    {isToday && <TodayLabel>{__('Dnes')}</TodayLabel>}
                  </DayTitleWrapper>
                  <Events>
                    {events.map((event, index) => {
                      return (
                        <StyledEvent
                          selected={currentEventId === event._id}
                          onClick={() => this.handleClickEvent(event._id)}
                          isFullScreen={this.props.isFullScreen}
                          key={`${index.toString()}-${event._id}`}
                          timeFrom={moment(event.dateFrom).format('H:mm')}
                          title={event.title}
                          color={event.color}
                          allDayEvent={event.allDayEvent}
                        />
                      );
                    })}
                  </Events>
                </Day>
              );
            })}
          </Row>
        </Scrollable>
      </Wrapper>
    );
  }
}

MonthView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  events: DatesType.isRequired,
  currentEventId: PropTypes.string,
  isFullScreen: PropTypes.bool.isRequired,
};

MonthView.defaultProps = {
  currentEventId: null,
};

const mapStateToProps = (state) => ({
  currentEventId: currentEventIdSelector(state),
});

export default connect(mapStateToProps)(MonthView);
