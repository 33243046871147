import {
  RECEIVE_EVENT_DETAIL_ERROR,
  RECEIVE_EVENT_DETAIL_SUCCESS,
  REQUEST_EVENT_DETAIL,
  SET_CURRENT_EVENT_ID,
} from './constants';

// Event Detail
export const eventDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_EVENT_DETAIL:
      return {
        ...state,
        [action.payload.eventId]: {
          ...state[action.payload.eventId],
          isFetching: true,
        },
      };
    case RECEIVE_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        [action.payload.eventId]: {
          ...state[action.payload.eventId],
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_EVENT_DETAIL_ERROR:
      return {
        ...state,
        [action.payload.eventId]: {
          ...state[action.payload.eventId],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

// Current event id
export const currentEventIdReducer = (state = null, action) => {
  if (action.type === SET_CURRENT_EVENT_ID) {
    return action.payload.id;
  }
  return state;
};
