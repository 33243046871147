import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';

const appSettingsDomainSelector = (state) => state.domain;

export const appSettingsIsFetchingSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => !!domain.isFetching,
);

export const appSettingsDidFailSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => !!domain.error,
);

export const appSettingsSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => getProp(domain, ['data'], {}),
);

export const CSMAppSpaceSelector = createSelector(
  appSettingsSelector,
  (settings) => {
    const appSpace = getProp(settings, ['CSMAppSpace'], '');
    return appSpace;
  },
);
