import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  background: ${({ visible }) =>
    visible ? `rgba(0,0,0,0.25)` : 'rgba(0,0,0,0)'};
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
`;

const Overlay = (props) => {
  const { visible, onClick } = props;

  return <Wrapper onClick={onClick} visible={visible} />;
};

Overlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Overlay;
