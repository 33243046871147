import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { browserHistory } from 'react-router';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import Api from './Api';
import CoreApi from './CoreApi';
import DomainsApi from './DomainsApi';
import commonReducers from './reducer';
import thunkMiddleware from 'redux-thunk';

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

export default function configureStore(preloadedState = {}) {
  return createStore(
    combineReducers({
      ...commonReducers,
      routing: routerReducer,
    }),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(browserHistory),
        thunkMiddleware.withExtraArgument({ Api, CoreApi, DomainsApi }),
      ),
    ),
  );
}
