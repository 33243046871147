import { __ } from '../../utilities';
import {
  codelistLabelByValueSelector,
  eventsByMonthsSelector,
} from '../App/selectors';
import { connect } from 'react-redux';
import {
  listViewIsFetchingSelector,
  listViewScrollTopSelector,
} from './selectors';
import { loadMoreEvents, setListViewScrollTop } from './actions';
import { openEventDetail, resetToday } from '../App/actions';
import { rem } from 'polished';
import { withRouter } from 'react-router';
import Button from '../../components/Button';
import EventList from '../../components/EventList';
import Icon from '../../components/Icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: ${rem(20)} 0;
  justify-content: center;
  height: ${rem(50)};
  width: 100%;
`;

const maxScrollBeforeLoadMore = 400;
const loadMoreDelay = 100;

const debounce = (func, delay = 1000) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

class ListView extends Component {
  constructor(props) {
    super(props);
    this.handleClickOnEvent = this.handleClickOnEvent.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreOnScroll = debounce(
      this.loadMoreOnScroll.bind(this),
      loadMoreDelay,
    );
    this.shouldScroll = true;
    this.saveScrollLocation = this.saveScrollLocation.bind(this);
    this.resetScrollState = this.resetScrollState.bind(this);
    this.goToScrollLocation = this.goToScrollLocation.bind(this);
  }

  componentDidMount() {
    this.wrapper.addEventListener('scroll', this.loadMoreOnScroll);
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { state: { today = false } = {} },
    } = this.props;
    const {
      location: { state: { today: nextToday = false } = {} },
    } = nextProps;
    if (!today && nextToday) {
      this.wrapper.scrollTop = 0; // scroll to today
    }
    if (this.shouldScroll) {
      this.goToScrollLocation();
    }
  }

  componentWillUnmount() {
    this.wrapper.removeEventListener('scroll', this.loadMoreOnScroll);
  }

  resetScrollState() {
    const {
      dispatch,
      location: { state: { today = false } = {} },
    } = this.props;
    if (today) {
      dispatch(resetToday());
    }
  }

  goToScrollLocation() {
    const { listViewScrollTop } = this.props;
    this.wrapper.scrollTop = listViewScrollTop;
    this.shouldScroll = false;
  }

  saveScrollLocation() {
    const { dispatch } = this.props;
    dispatch(setListViewScrollTop(this.wrapper.scrollTop));
  }

  loadMoreOnScroll() {
    const { dispatch, listViewIsFetching } = this.props;
    this.saveScrollLocation();
    this.resetScrollState();
    const bottomY =
      0 +
      this.wrapper.scrollHeight -
      this.wrapper.scrollTop -
      this.wrapper.offsetHeight;
    if (!listViewIsFetching && bottomY < maxScrollBeforeLoadMore) {
      dispatch(loadMoreEvents());
    }
  }

  loadMore() {
    const { dispatch } = this.props;
    dispatch(loadMoreEvents());
  }

  handleClickOnEvent(id) {
    this.props.dispatch(openEventDetail(id));
  }

  render() {
    const { eventsByMonth, listViewIsFetching, theme, countryLabelByValue } =
      this.props;
    return (
      <Wrapper
        ref={(el) => {
          this.wrapper = el;
        }}
      >
        {Object.keys(eventsByMonth).map((month) => {
          return (
            <EventList
              key={month}
              month={month}
              eventsByDay={eventsByMonth[month]}
              onClick={(id) => this.handleClickOnEvent(id)}
              countryLabelByValue={countryLabelByValue}
            />
          );
        })}
        <LoadMoreWrapper>
          {listViewIsFetching ? (
            <Icon
              name="loading"
              color={theme.color.primary}
              width="50"
              height="50"
              borderWidth={6}
            />
          ) : (
            <Button isActive primary basic onClick={this.loadMore}>
              {__('Načítať viac')}
            </Button>
          )}
        </LoadMoreWrapper>
      </Wrapper>
    );
  }
}

ListView.propTypes = {
  eventsByMonth: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  listViewIsFetching: PropTypes.bool.isRequired,
  listViewScrollTop: PropTypes.number.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }).isRequired,
  countryLabelByValue: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    eventsByMonth: eventsByMonthsSelector(state),
    listViewIsFetching: listViewIsFetchingSelector(state),
    listViewScrollTop: listViewScrollTopSelector(state),
    countryLabelByValue: codelistLabelByValueSelector(state, 'country'),
  };
};

const routerConnected = withRouter(ListView);
const themed = withTheme(routerConnected);
export default connect(mapStateToProps)(themed);
