import {
  RECEIVE_MORE_EVENTS,
  REQUEST_MORE_EVENTS,
  SET_SCROLL_TOP,
} from './constants';

// Appspace
export const listViewReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_MORE_EVENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_MORE_EVENTS:
      return {
        ...state,
        isFetching: false,
      };
    case SET_SCROLL_TOP:
      return {
        ...state,
        scrollTop: action.payload.scrollTop,
      };
    default:
      return state;
  }
};
