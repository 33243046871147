import { darken, rem } from 'polished';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider, css, withTheme } from 'styled-components';

const customDarken = (amount, color) => {
  if (color === 'transparent') {
    return `rgba(0, 0, 0, ${amount})`;
  }
  return darken(amount, color);
};

const Wrapper = styled.button`
  height: ${rem(40)};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  color: ${({ theme }) => theme.buttonTextColor || theme.textColor};
  vertical-align: baseline;
  padding: 0 ${rem(15)};
  ${({ withoutContent, icon }) =>
    withoutContent &&
    icon &&
    css`
      padding: 0 ${rem(15)};
    `} text-transform: none;
  text-shadow: none;
  font-weight: 500;
  font-style: normal;
  font-size: ${rem(14)};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            background: ${({ theme }) =>
              customDarken(0.05, theme.buttonBackgroundColor)};
          }
          &:focus {
            background: ${({ theme }) =>
              customDarken(0.1, theme.buttonBackgroundColor)};
          }
          &:active {
            background: ${({ theme }) =>
              customDarken(0.2, theme.buttonBackgroundColor)};
          }
        `};

  &:hover {
    path {
      fill: ${({ theme }) => theme.color.primary};
    }
    rect {
      fill: ${({ theme }) => theme.color.primary};
    }
    color: ${({ theme }) => theme.color.primary};
  }
`;

const StyledIcon = styled(Icon)`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-right: ${rem(9)};
    `};
`;

const Content = styled.div`
  transition: opacity 0.2s;
  opacity: ${({ loading }) => (loading ? 0.25 : 1)};
  display: flex;
  align-items: center;
`;

const Button = (props) => {
  const { theme, primary, block, basic, loading, disabled, onClick, isActive } =
    props;

  let buttonTheme = {
    buttonBackgroundColor: 'transparent',
    buttonTextColor: theme.textColor,
  };

  // basic states
  if (primary) {
    if (basic) {
      buttonTheme = {
        ...buttonTheme,
      };
    } else {
      buttonTheme = {
        ...buttonTheme,
        buttonBackgroundColor: theme.color.primary,
        buttonTextColor: '#fff',
      };
    }
  }

  if (isActive) {
    buttonTheme = {
      ...buttonTheme,
      buttonTextColor: theme.color.primary,
    };
  }

  if (disabled) {
    if (basic) {
      buttonTheme = {
        ...buttonTheme,
        buttonTextColor: theme.inactiveColor,
      };
    } else {
      buttonTheme = {
        ...buttonTheme,
        buttonBackgroundColor: theme.inactiveColor,
        buttonTextColor: '#fff',
      };
    }
  }

  // nastavime spravny border
  if (basic) {
    buttonTheme = {
      ...buttonTheme,
      buttonBorderColor: buttonTheme.buttonTextColor,
    };
  } else {
    buttonTheme = {
      ...buttonTheme,
      buttonBorderColor: buttonTheme.buttonBackgroundColor,
    };
  }

  return (
    <ThemeProvider theme={buttonTheme}>
      <Wrapper
        className={`btn ${props.className}`}
        block={block}
        disabled={disabled}
        icon={props.icon}
        withoutContent={!props.children}
        onClick={(e) => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        <Content loading={loading}>
          {props.icon && (
            <StyledIcon
              width={16}
              height={16}
              noMargin={!props.children}
              name={props.icon}
              color={buttonTheme.buttonTextColor}
            />
          )}
          {props.children}
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

Button.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  primary: PropTypes.bool,
  block: PropTypes.bool,
  basic: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    textColor: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};
Button.defaultProps = {
  isActive: false,
  className: '',
  children: null,
  primary: false,
  block: false,
  basic: false,
  loading: false,
  disabled: false,
  icon: null,
  onClick: () => {},
};

export default withTheme(Button);
