import * as React from 'react';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { connect } from 'react-redux';
import { getProp, objectToParams } from 'sportnet-utilities';
import { isLoggedInSelector, loggedInUserSelector } from '../App/selectors';
import { setAuthUser } from '../App/actions';
import Api from '../../Api';
import Cookies from 'js-cookie';
import CoreApi from '../../CoreApi';
import authContext from './authContext';

export const TOKEN_COOKIE_KEY = 'calendar_token';

const queryStringToObject = (params) => {
  const result = {};
  if (params.length === 0) {
    return result;
  }
  const query = params.substr(1);
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

function getCallbackAuthUrl(appSpace) {
  let redirectUri = '';
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    redirectUri = window.location.href;
  }
  const params = {
    client_id: 'calendar',
    response_type: 'token',
    redirect_uri: redirectUri,
    appSpace,
    scope: ['profile', 'groups'],
    state: '',
  };
  return `https://my.sportnet.online/auth/authorize?${objectToParams(params)}`;
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  loggedInUser: loggedInUserSelector(state),
  appSettings: appSettingsSelector(state),
});

const mapDispatchToProps = {
  setAuthResponse: setAuthUser,
};

class Authorization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: true,
    };

    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      const response = queryStringToObject(window.location.hash);
      let token = getProp(response, ['access_token']);

      if (!token) {
        token = Cookies.get(TOKEN_COOKIE_KEY);
      }

      if (token) {
        window.location.hash = '';
        this.state = {
          initialized: false,
        };
        this.verifyToken(token);
      }
    }
  }

  async verifyToken(token) {
    try {
      CoreApi.setToken(token);
      const res = await CoreApi.me();
      Cookies.set(TOKEN_COOKIE_KEY, token);
      Api.setToken(token);
      this.props.setAuthResponse({
        accessToken: token,
        user: res,
      });
      this.setState({
        initialized: true,
      });
    } catch (e) {
      this.setState({
        initialized: true,
      });
      Cookies.remove(TOKEN_COOKIE_KEY);
    }
  }

  login = (appspace) => {
    window.location.href = getCallbackAuthUrl(
      this.props.appSettings.CSMAppSpace || '',
    );
  };

  logout = () => {
    Cookies.remove(TOKEN_COOKIE_KEY);
    window.location.reload();
  };

  render() {
    const { children, isLoggedIn, loggedInUser } = this.props;
    const { initialized } = this.state;
    if (!initialized) {
      return null;
    }
    return (
      <authContext.Provider
        value={{
          login: this.login,
          logout: this.logout,
          loggedInUser: loggedInUser,
          isLoggedIn,
        }}
      >
        {children}
      </authContext.Provider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
