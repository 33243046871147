import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PerexParagraph = styled.p`
  text-align: start;
  margin: 0;
  &:last-of-type {
    margin-bottom: ${rem(15)};
  }
`;

const Perex = ({ text }) => {
  return text
    .split('\n')
    .map((p, idx) => <PerexParagraph key={idx}>{p}</PerexParagraph>);
};

Perex.propTypes = {
  text: PropTypes.string.isRequired,
};

export default React.memo(Perex);
