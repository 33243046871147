import {
  RECEIVE_APP_SETTINGS_ERROR,
  RECEIVE_APP_SETTINGS_SUCCESS,
  REQUEST_APP_SETTINGS,
} from './constants';
import { setAppspace } from '../App/actions';

export const requestAppSettings = (eventId) => ({
  type: REQUEST_APP_SETTINGS,
  payload: {
    eventId,
  },
});

export const receiveAppSettingsSuccess = (payload) => ({
  type: RECEIVE_APP_SETTINGS_SUCCESS,
  payload,
});

export const receiveAppSettingsError = (error) => ({
  type: RECEIVE_APP_SETTINGS_ERROR,
  payload: {
    error,
  },
});

export const loadAppSettings = (domain) => {
  return async (dispatch, getState, { Api, CoreApi, DomainsApi }) => {
    dispatch(requestAppSettings());
    try {
      const appSpaceDomainMatch = domain.match(/^appspace\.(.+)$/i);
      let baseUri;
      let ppo;
      let appId = 'calendar';
      if (domain === 'public.calendar.sportnet.online') {
        const match =
          /(https:\/\/public\.calendar\.sportnet\.online)([/])?([a-zA-Z0-9._-]+)(\/)?/.exec(
            `https://${domain}${window.location.pathname}${window.location.search}`,
          );
        if (match && match.length === 5) {
          const settings = await Api.publicGetSettings(match[3]);
          if (settings.domain) {
            window.location.replace(`https://${settings.domain}`);
            return;
          } else {
            // window.location.replace(`https://${match[3]}.${domain}`);
          }
        }
      } else if (appSpaceDomainMatch) {
        baseUri = appSpaceDomainMatch[0];
        ppo = appSpaceDomainMatch[1];
      } else {
        const resolvedDomain = await DomainsApi.getDomainInfo(domain);
        baseUri = resolvedDomain._id;
        ppo = resolvedDomain.ppo;
        appId = resolvedDomain.appId;
      }

      if (ppo && appId) {
        const app = await CoreApi.getPublicApp(appId);
        const settings = await Api.publicGetSettings(ppo);
        const organizationProfile = await CoreApi.ppoInvoiceProfile(ppo);
        dispatch(setAppspace(ppo));
        dispatch(
          receiveAppSettingsSuccess({
            CSMAppSpace: ppo,
            baseUri: `https://${baseUri}`.replace(/\/$/, ''),
            logoUrl: organizationProfile.logo_public_url,
            organizationName:
              organizationProfile.full_name || organizationProfile.name,
            app,
            ...settings,
          }),
        );
      } else {
        dispatch(receiveAppSettingsError('DOMAIN_NOT_SET'));
      }
    } catch (e) {
      console.error(e);
      dispatch(receiveAppSettingsError(e));
    }
  };
};
