import 'react-datepicker/dist/react-datepicker.css';
import * as React from 'react';
import { __ } from '../../utilities';
import {
  appspaceSelector,
  calendarsIsFetchingSelector,
  calendarsSelector,
} from './selectors';
import { connect } from 'react-redux';
import { rem } from 'polished';
import Button from '../../components/Button';
import CustomSelect from '../../components/CustomSelect';
import DatePicker from 'react-datepicker';
import Icon from '../../components/Icon';
import PropTypes from 'prop-types';
import authContext from '../Authorization/authContext';
import moment from 'moment';
import styled, { css, withTheme } from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: ${({ isFullScreen }) => (isFullScreen ? rem(90) : rem(70))};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  align-items: center;
  flex-shrink: 0;
  height: ${rem(61)};
  ${({ theme, isFullScreen }) => theme.media.sm`
    height: ${isFullScreen ? rem(90) : rem(70)};
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: normal;
  flex-direction: column;
`;

const StyledFullViewButton = styled(Button)`
  font-size: ${rem(12)};
  padding: 0 ${rem(3)};
  ${({ theme }) => theme.media.sm`
    font-size: ${rem(14)};
    padding: 0 ${rem(15)};
  `};
`;

const AuthButton = styled(Button)`
  height: inherit;
  font-size: ${rem(12)};
  border-left: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  width: ${rem(60)};
  ${({ theme }) => theme.media.sm`
    width: ${rem(90)};
  `};
  svg {
    height: 24px;
    width: 24px;
  }
  > div {
    flex-direction: column;
    align-items: center;
    > span {
      margin: 0;
    }
  }
`;

const MenuButton = styled(Button)`
  height: inherit;
  width: ${rem(60)};
  flex: 1;
  ${({ theme }) => theme.media.sm`
    width: ${rem(90)};
  `};
`;

const StyledDatePicker = styled(DatePicker)``;

const DatePickerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 ${rem(15)};
  font-size: ${rem(14)};
  border-left: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  color: ${({ theme }) => theme.textColor};
  height: 100%;

  .react-datepicker-popper {
    z-index: 10;
  }
  &:last-child {
    padding-right: 0;
  }

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  ${({ isPhone }) =>
    !isPhone &&
    css`
      .react-datepicker {
        position: absolute;
        top: -20px;
      }
    `}

  .react-datepicker-wrapper {
    height: 100%;
    width: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    & > input {
      height: 100%;
    }
  }
`;

const DatePickerButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: ${rem(14)};
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.color.base};
  flex-direction: column;

  &:focus {
    outline: none;
  }
`;

const ArrowDown = styled(Icon)`
  display: flex;
  margin-left: auto;
  position: absolute;
  right: ${rem(15)};
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0 ${rem(3)};
  ${({ theme }) => theme.media.sm`
    padding: 0 ${rem(15)};
    margin: 0 ${rem(7.5)};
  `};
`;

const DateWrapper = styled.div`
  margin-left: 0;
  font-size: ${rem(16)};
  text-align: center;
  &::first-letter {
    text-transform: uppercase;
  }

  ${({ theme }) => theme.media.sm`
    margin-left: ${rem(30)};
  font-size: ${rem(24)};
    
  `};
`;

const StyledTodayButton = styled(Button)`
  margin-left: 0;
  font-size: ${rem(16)};
  ${({ theme }) => theme.media.sm`
    margin-left: ${rem(30)};
  `};
`;

const ButtonViewTypeWrapper = styled.div`
  margin-right: ${rem(10)};
  button {
    height: 1.5rem;
    ${({ theme }) => theme.media.sm`
      height: 2.5rem;
  `};
    display: none;
    ${({ theme }) => theme.media.sm`
      display: initial;
  `};
  }
`;

const DatepickersContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-grow: 1;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

const SelectWrapper = styled.div`
  display: flex;
`;

const Header = ({
  date,
  toggleSidebar,
  isFullScreen,
  view,
  dateTo,
  isPhone,
  dateFrom,
  theme,
  onChangeStartDate,
  onChangeEndDate,
  onClickLeftButton,
  onClickRightButton,
  onClickToday,
  onClickMonth,
  onClickWeek,
  onClickList,
  appspace,
}) => {
  const authCtx = React.useContext(authContext);
  const monthAndYear = moment(date).format('MMMM YYYY');
  const momentDateFrom = moment(dateFrom);
  const momentDateTo = moment(dateTo);

  return (
    <Wrapper isFullScreen={isFullScreen}>
      <LeftHeaderWrapper>
        <IconWrapper onClick={toggleSidebar}>
          <MenuButton icon="hamburger" />
        </IconWrapper>
        {view === 'list' ? (
          <DatepickersContentWrapper>
            <DatePickerWrapper isPhone={isPhone}>
              <StyledDatePicker
                selectsStart
                showYearDropdown
                customInput={
                  <DatePickerButton>
                    <small>{__('Od: ')}</small>
                    <span>{momentDateFrom.format('DD.MM.YYYY')}</span>
                  </DatePickerButton>
                }
                selected={momentDateFrom}
                startDate={momentDateFrom}
                endDate={momentDateTo}
                onChange={onChangeStartDate}
                withPortal={isPhone}
              />
              <ArrowDown name="dropdown-arrow" color={theme.color.base} />
            </DatePickerWrapper>
            <DatePickerWrapper isPhone={isPhone}>
              <StyledDatePicker
                selectsEnd
                showYearDropdown
                customInput={
                  <DatePickerButton>
                    <small>{__('Do: ')}</small>
                    <span>{momentDateTo.format('DD.MM.YYYY')}</span>
                  </DatePickerButton>
                }
                selected={momentDateTo}
                startDate={momentDateFrom}
                minDate={momentDateFrom}
                endDate={momentDateTo}
                onChange={onChangeEndDate}
                withPortal={isPhone}
              />
              <ArrowDown name="dropdown-arrow" color={theme.color.base} />
            </DatePickerWrapper>
          </DatepickersContentWrapper>
        ) : (
          <ContentWrapper>
            <ButtonsWrapper>
              <StyledButton
                primary
                basic
                icon="arrow-left"
                onClick={onClickLeftButton}
              />
              <StyledButton
                primary
                basic
                icon="arrow-right"
                onClick={onClickRightButton}
              />
            </ButtonsWrapper>
            <DateWrapper>{monthAndYear}</DateWrapper>
            <StyledTodayButton
              primary
              basic
              icon="calendar"
              onClick={onClickToday}
            >
              {__('Dnes')}
            </StyledTodayButton>
          </ContentWrapper>
        )}
      </LeftHeaderWrapper>
      {!isPhone && isFullScreen ? (
        <ButtonViewTypeWrapper>
          <StyledFullViewButton
            isActive={view === 'month'}
            primary
            basic
            icon="month-view"
            onClick={onClickMonth}
          >
            {__('Mesačný')}
          </StyledFullViewButton>
          <StyledFullViewButton
            isActive={view === 'week'}
            primary
            basic
            icon="week-view"
            onClick={onClickWeek}
          >
            {__('Týždenný')}
          </StyledFullViewButton>
          <StyledFullViewButton
            isActive={view === 'list'}
            primary
            basic
            icon="list-view"
            onClick={onClickList}
          >
            {__('Zoznam')}
          </StyledFullViewButton>
        </ButtonViewTypeWrapper>
      ) : (
        <SelectWrapper>
          {!isPhone && (
            <CustomSelect
              onClickMonth={onClickMonth}
              onClickWeek={onClickWeek}
              onClickList={onClickList}
              selectedType={view}
            />
          )}
        </SelectWrapper>
      )}
      {authCtx.isLoggedIn ? (
        <AuthButton primary basic icon="logout" onClick={authCtx.logout}>
          {authCtx.loggedInUser.name} ({__('Odhlásiť')})
        </AuthButton>
      ) : (
        <AuthButton
          primary
          basic
          icon="person"
          onClick={() => authCtx.login(appspace)}
        >
          {__('Prihlásiť')}
        </AuthButton>
      )}
    </Wrapper>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  onClickRightButton: PropTypes.func.isRequired,
  onClickLeftButton: PropTypes.func.isRequired,
  onClickToday: PropTypes.func.isRequired,
  onClickList: PropTypes.func.isRequired,
  onClickMonth: PropTypes.func.isRequired,
  onClickWeek: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  isPhone: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      base: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    appspace: appspaceSelector(state),
    calendars: calendarsSelector(state),
    calendarsIsFetching: calendarsIsFetchingSelector(state),
  };
};

const themed = withTheme(Header);
export default connect(mapStateToProps)(themed);
