import {
  appSettingsReducer,
  appspaceReducer,
  authorizationReducer,
  calendarsReducer,
  entitiesReducer,
  eventsReducer,
  loading,
} from './containers/App/reducers';
import {
  currentEventIdReducer,
  eventDetailReducer,
} from './containers/EventDetail/reducers';
import { appSettingsReducer as domainsReducer } from './containers/DomainResolver/reducer';
import { reducer as formReducer } from 'redux-form';
import { listViewReducer } from './containers/ListView/reducers';
import { routerReducer } from 'react-router-redux';

export default {
  authorization: authorizationReducer,
  loading,
  routing: routerReducer,
  form: formReducer,
  entities: entitiesReducer,
  calendars: calendarsReducer,
  eventDetailById: eventDetailReducer,
  appspace: appspaceReducer,
  appSettings: appSettingsReducer,
  eventsByDate: eventsReducer,
  currentEventId: currentEventIdReducer,
  listView: listViewReducer,
  domain: domainsReducer,
};
