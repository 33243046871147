import {
  RECEIVE_APP_SETTINGS_ERROR,
  RECEIVE_APP_SETTINGS_SUCCESS,
  REQUEST_APP_SETTINGS,
} from './constants';

export const appSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_APP_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.payload,
      };
    case RECEIVE_APP_SETTINGS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
