const namespace = 'App';

// Auth
export const SET_AUTH_USER = `${namespace}/SET_AUTH_USER`;

// Calendars
export const REQUEST_CALENDARS = `${namespace}/REQUEST_CALENDARS`;
export const RECEIVE_CALENDARS_SUCCESS = `${namespace}/RECEIVE_CALENDARS_SUCCESS`;
export const RECEIVE_CALENDARS_ERROR = `${namespace}/RECEIVE_CALENDARS_ERROR`;

// Groups
export const RECEIVE_CALENDAR_GROUPS_SUCCESS = `${namespace}/RECEIVE_CALENDAR_GROUPS_SUCCESS`;

// Appspace
export const SET_APPSPACE = `${namespace}/SET_APPSPACE`;

// Events
export const REQUEST_EVENTS = `${namespace}/REQUEST_EVENTS`;
export const RECEIVE_EVENTS_SUCCESS = `${namespace}/RECEIVE_EVENTS_SUCCESS`;
export const RECEIVE_EVENTS_ERROR = `${namespace}/RECEIVE_EVENTS_ERROR`;

// App settings
export const SET_APP_SETTINGS = `${namespace}/SET_APP_SETTINGS`;

// Loading

export const LOADING_ADD = `${namespace}/LOADING_ADD`;
export const LOADING_REMOVE = `${namespace}/LOADING_REMOVE`;

export const RECEIVE_CODELIST_SUCCESS = `${namespace}/RECEIVE_CODELIST_SUCCESS`;
