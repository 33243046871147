import { darken, rem, rgba } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  font-size: ${rem(12)};
  padding: ${rem(10)};
  transition: background-color 0.4s;
  background-color: ${({ color, theme }) =>
    color ? rgba(color, 0.25) : rgba(theme.defaultEventColor, 0.25)};
  color: ${({ color }) => (color ? darken(0.4, color) : '#fff')};
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
  ${({ onClick }) => onClick !== null && 'cursor: pointer'};
  ${({ selected }) =>
    selected &&
    css`
      z-index: 6;
      background-color: ${({ color, theme }) =>
        color ? rgba(color, 0.75) : rgba(theme.defaultEventColor, 0.75)};
    `};
`;

const Event = ({
  timeFrom,
  title,
  color,
  className,
  onClick,
  selected,
  allDayEvent,
}) => {
  return (
    <Wrapper
      className={className}
      color={color}
      onClick={typeof onClick === 'function' ? onClick : null}
      selected={selected}
      title={title}
    >
      {timeFrom && !allDayEvent ? `${timeFrom} - ` : null}
      <strong>{title}</strong>
    </Wrapper>
  );
};

Event.propTypes = {
  timeFrom: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  allDayEvent: PropTypes.bool.isRequired,
};

Event.defaultProps = {
  timeFrom: null,
  selected: false,
  onClick: null,
};

export default Event;
