import { calendarColorMapSelector, entitySelector } from '../App/selectors';
import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

// Event Detail
const eventDetailDomainSelector = (state) => state.eventDetailById;

const eventDetailObjectSelector = (id) =>
  createSelector(eventDetailDomainSelector, (substate) =>
    getProp(substate, [id], {}),
  );

export const eventDetailIsFetchingSelector = (id) =>
  createSelector(eventDetailObjectSelector(id), (substate) =>
    getProp(substate, ['isFetching'], false),
  );

export const eventDetailErrorSelector = (id) =>
  createSelector(eventDetailObjectSelector(id), (substate) =>
    getProp(substate, ['error'], null),
  );

export const eventDetailSelector = (id) =>
  createSelector(
    entitySelector('events', id),
    calendarColorMapSelector,
    (eventDetail, colorMap) => {
      const event = eventDetail;
      const { calendarId } = event;
      if (calendarId) {
        event.color = getProp(colorMap, [calendarId], null);
      }
      return event;
    },
  );

export const currentEventIdSelector = (state) => state.currentEventId;
