import {
  RECEIVE_EVENT_DETAIL_ERROR,
  RECEIVE_EVENT_DETAIL_SUCCESS,
  REQUEST_EVENT_DETAIL,
  SET_CURRENT_EVENT_ID,
} from './constants';
import { addLoading, removeLoading } from '../App/actions';
import { appspaceSelector } from '../App/selectors';
import { eventSchema } from '../../schemas';
import { normalize } from 'normalizr';

// Event detail
export const requestEventDetail = (eventId) => ({
  type: REQUEST_EVENT_DETAIL,
  payload: {
    eventId,
  },
});

export const receiveEventDetailSuccess = (eventId, entities) => ({
  type: RECEIVE_EVENT_DETAIL_SUCCESS,
  payload: {
    eventId,
    entities,
  },
});

export const receiveEventDetailError = (eventId, error) => ({
  type: RECEIVE_EVENT_DETAIL_ERROR,
  payload: {
    eventId,
    error,
  },
});

export const loadEventDetail = (eventId) => {
  return async (dispatch, getState, { Api }) => {
    dispatch(requestEventDetail(eventId));
    dispatch(addLoading());
    try {
      const appspace = appspaceSelector(getState());
      const data = await Api.getEventDetailPublic(eventId, appspace);
      const normalized = normalize(data, eventSchema);
      dispatch(receiveEventDetailSuccess(eventId, normalized.entities));
      dispatch(removeLoading());
    } catch (error) {
      dispatch(receiveEventDetailError(eventId, error));
      dispatch(removeLoading());
    }
  };
};

// Current event id
export const setCurrentEventId = (id) => ({
  type: SET_CURRENT_EVENT_ID,
  payload: {
    id,
  },
});
