// Moment initialisation with locale
import 'moment/locale/sk';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import React from 'react';
import moment from 'moment';
// React router
import { theme as baseTheme } from './theme';
import { browserHistory } from 'react-router';
import { getProp } from './utilities';
import { syncHistoryWithStore } from 'react-router-redux';
import Api from './Api';
import CoreApi from './CoreApi';
import DomainsApi from './DomainsApi';
import GlobalStyle from './globalStyles';
import Routes from './routes';
import configureStore from './configureStore';
import styled, { ThemeProvider } from 'styled-components';

Api.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
CoreApi.setBaseUrl(process.env.REACT_APP_CORE_API_BASE_URL);
DomainsApi.setBaseUrl(process.env.REACT_APP_DOMAINS_API_BASE_URL);
moment.updateLocale('sk', {
  week: {
    dow: 1,
  },
});

const defaultState = {};
let state = {};
if (typeof window !== 'undefined') {
  state = getProp(window, ['__REDUX_STATE__'], '{{SSR_INITIAL_STATE}}');
}

// Initialise redux store
const store = configureStore(
  state !== '{{SSR_INITIAL_STATE}}' ? state : defaultState,
);

let history = {};

if (typeof window !== 'undefined') {
  history = syncHistoryWithStore(browserHistory, store);
}

const Wrapper = styled.div`
  height: 100%;
  color: ${({ theme }) => theme.textColor};
`;

if (typeof window !== 'undefined') {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={baseTheme}>
        <>
          <GlobalStyle />
          <Wrapper>
            <Routes history={history} />
          </Wrapper>
        </>
      </ThemeProvider>
    </Provider>,
  );
}
