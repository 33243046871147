import PropTypes from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const Span = styled.span`
  display: flex;
`;

const Icon = (props) => {
  const {
    name,
    color,
    theme,
    innerRef,
    className,
    width,
    height,
    borderWidth,
  } = props;
  const iconColor = color;

  let content = null;

  if (name === 'hamburger') {
    content = (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17">
        <path
          id="Path_8"
          fill={color || theme.color.icon}
          d="M1,0H23a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1H1A.945.945,0,0,1,0,1,.945.945,0,0,1,1,0Z"
        />
        <path
          id="Path_9"
          fill={color || theme.color.icon}
          d="M1,7H23a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1H1A.945.945,0,0,1,0,8,.945.945,0,0,1,1,7Z"
        />
        <path
          id="Path_10"
          fill={color || theme.color.icon}
          d="M1,14H23a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2Z"
        />
      </svg>
    );
  } else if (name === 'close') {
    content = (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <path
          fill={color || theme.color.icon}
          d="M16,1.4,14.6,0,8,6.6,1.4,0,0,1.4,6.6,8,0,14.6,1.4,16,8,9.4,14.6,16,16,14.6,9.4,8Z"
        />
      </svg>
    );
  } else if (name === 'check') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 19}
        height={height || 18}
        viewBox="0 0 19 18"
      >
        <path
          fill="none"
          stroke={iconColor}
          strokeWidth="3"
          d="M16.244 2.62L7.049 13.65 1.602 9.551"
        />
      </svg>
    );
  } else if (name === 'dropdown-arrow') {
    content = (
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4">
        <path fill={color || theme.color.icon} d="M8,0,4,4,0,0Z" />
      </svg>
    );
  } else if (name === 'loading') {
    content = (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={color}
          strokeWidth={borderWidth}
          r="30"
          strokeDasharray="141.37166941154067 49.12388980384689"
          transform="rotate(336 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    );
  } else if (name === 'calendar') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={width || '16'}
        height={height || '16'}
      >
        <path
          fill={color}
          d="M15,16H1a.945.945,0,0,1-1-1V2A.945.945,0,0,1,1,1H4V0H6V1h4V0h2V1h3a.945.945,0,0,1,1,1V15A.945.945,0,0,1,15,16ZM14,3H12V4H10V3H6V4H4V3H2V5H14Zm0,4H2v7H14Zm-1,6H10V10h3Z"
        />
      </svg>
    );
  } else if (name === 'web') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={width || '16'}
        height={height || '16'}
      >
        <path
          fill={color || '#000'}
          d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0Zm5.9,7.1a6.59,6.59,0,0,1-2.4,1.4V8.1A7.423,7.423,0,0,0,8.9,2.2,5.73,5.73,0,0,1,13.9,7.1ZM8,2.6A6.388,6.388,0,0,1,10.5,8v.7A8.751,8.751,0,0,1,8.1,9a10.319,10.319,0,0,1-2.6-.3V8A6.388,6.388,0,0,1,8,2.6Zm-.9-.5A7.423,7.423,0,0,0,4.5,8v.4A7.092,7.092,0,0,1,2.1,6.9,6.066,6.066,0,0,1,7.1,2.1ZM2,8.1A8.914,8.914,0,0,0,4.6,9.5,7.472,7.472,0,0,0,7,14,6.1,6.1,0,0,1,2,8.1Zm6,5.3A6.4,6.4,0,0,1,5.7,9.7a8.751,8.751,0,0,0,2.4.3,14.979,14.979,0,0,0,2.2-.2A6.42,6.42,0,0,1,8,13.4Zm.9.5a7.471,7.471,0,0,0,2.4-4.4,7.015,7.015,0,0,0,2.6-1.3A5.809,5.809,0,0,1,8.9,13.9Z"
        />
      </svg>
    );
  } else if (name === 'time') {
    content = (
      <svg
        id="time"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={width || '16'}
        height={height || '16'}
      >
        <path
          fill={color || '#000'}
          d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,14a6,6,0,1,1,6-6A6.018,6.018,0,0,1,8,14Z"
        />
        <path fill={color || '#000'} d="M9,4H7V9h5V7H9Z" />
      </svg>
    );
  } else if (name === 'arrow-right') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 10 16"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M1.361 15.998l-1.36-1.471 7.051-6.528L.001 1.471l1.36-1.47 8.638 7.998-8.638 7.999z"
        />
      </svg>
    );
  } else if (name === 'arrow-left') {
    content = (
      <svg
        width={width || '16'}
        height={height || '16'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 16"
      >
        <path
          transform="rotate(-180 4.999999523162843,7.999499797821045) "
          id="svg_1"
          d="m1.361,15.998l-1.36,-1.471l7.051,-6.528l-7.051,-6.528l1.36,-1.47l8.638,7.998l-8.638,7.999z"
          fillRule="evenodd"
          fill={color}
        />
      </svg>
    );
  } else if (name === 'list-view') {
    content = (
      <svg
        id="day_view"
        viewBox="0 0 14 14"
        width={width || '16'}
        height={height || '16'}
      >
        <rect width="14" height="2" fill={color} />
        <rect width="14" height="2" transform="translate(0 4)" fill={color} />
        <rect width="14" height="2" transform="translate(0 8)" fill={color} />
        <rect width="14" height="2" transform="translate(0 12)" fill={color} />
      </svg>
    );
  } else if (name === 'week-view') {
    content = (
      <svg
        id="week_view"
        viewBox="0 0 14 14"
        width={width || '16'}
        height={height || '16'}
      >
        <rect width="2" height="14" fill={color} />
        <rect width="2" height="14" transform="translate(4)" fill={color} />
        <rect width="2" height="14" transform="translate(8)" fill={color} />
        <rect width="2" height="14" transform="translate(12)" fill={color} />
      </svg>
    );
  } else if (name === 'month-view') {
    content = (
      <svg
        id="month_view"
        viewBox="0 0 14 14"
        width={width || '16'}
        height={height || '16'}
      >
        <rect width="6" height="6" fill={color} />
        <rect width="6" height="6" transform="translate(0 8)" fill={color} />
        <rect width="6" height="6" transform="translate(8 8)" fill={color} />
        <rect width="6" height="6" transform="translate(8)" fill={color} />
      </svg>
    );
  } else if (name === 'file') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 12}
        height={width || 14}
        viewBox="0 0 12 14"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M11.972 12.999c0 .553-.449 1.001-1.001 1.001h-9.97A1.001 1.001 0 0 1 0 12.999V1.005c0-.552.448-1 1-1.001L6.999 0H7l5 5s-.028 4.886-.028 7.999zM5.999 6V2h-.005v.001l-3.992.003v9.995h7.969c.005-2.541.026-5.978.026-5.999H5.999z"
        />
      </svg>
    );
  } else if (name === 'person') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={width || 24}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        />
      </svg>
    );
  } else if (name === 'logout') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={width || 24}
        viewBox="0 0 24 24"
      >
        <g>
          <path
            fill={color}
            d="M9,20H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h4c0.6,0,1-0.4,1-1S9.6,2,9,2H5C3.3,2,2,3.3,2,5v14
		c0,1.7,1.3,3,3,3h4c0.6,0,1-0.4,1-1S9.6,20,9,20z"
          />
          <path
            fill={color}
            d="M21.9,12.4c0.1-0.2,0.1-0.5,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.3l-5-5c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.3,3.3H9
		c-0.6,0-1,0.4-1,1s0.4,1,1,1h9.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5
		C21.8,12.6,21.9,12.5,21.9,12.4z"
          />
        </g>
      </svg>
    );
  } else if (name === 'place') {
    content = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 16}
        height={width || 16}
        viewBox="0 0 24 24"
      >
        <path
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
          fill={color}
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }

  if (content) {
    return (
      <Span className={`icon ${className}`} ref={innerRef}>
        {content}
      </Span>
    );
  }
  return null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  innerRef: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.shape({
    color: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }).isRequired,
  borderWidth: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  color: null,
  innerRef: undefined,
  width: null,
  height: null,
  borderWidth: '',
};

export default withTheme(Icon);
