import { ThemeProvider } from 'styled-components';
import Content from '@sportnet/content/view';
import PropTypes from 'prop-types';
import React from 'react';
import sportnetUITheme from '@sportnet/ui/Themes/baseTheme';

const contentTheme = {
  ...sportnetUITheme,
  app: {
    maxWidth: 1200,
    fontName: 'Roboto',
    padding: 0,
    bgColor: '#fff',
    secondaryBgColor: '#f1f1f1',
    textColor: '#555',
    secondaryTextColor: '#707070',
  },
};

const ContentView = ({ items }) => {
  return (
    <ThemeProvider theme={contentTheme}>
      <Content
        items={items}
        renderLink={(link) => {
          if (link.type === 'article_detail') {
            return (
              <span className={link.linkProps.className}>
                {link.linkProps.children}
              </span>
            );
          }
          if (link.type === 'article_filter') {
            if (typeof link.linkProps.children !== 'string') {
              return null;
            }
            return (
              <span className={link.linkProps.className}>
                {link.linkProps.children}
              </span>
            );
          }
          if (link.section) {
            return (
              <span className={link.linkProps.className}>
                {link.linkProps.children}
              </span>
            );
          }
          return null;
        }}
      />
    </ThemeProvider>
  );
};

ContentView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(ContentView);
