import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const listViewDomainSelector = state => state.listView;

export const listViewScrollTopSelector = createSelector(
  listViewDomainSelector,
  domain => domain.scrollTop || 0,
);

export const listViewIsFetchingSelector = createSelector(
  listViewDomainSelector,
  domain => getProp(domain, ['isFetching'], false),
);
