import {
  LOADING_ADD,
  LOADING_REMOVE,
  RECEIVE_CALENDARS_ERROR,
  RECEIVE_CALENDARS_SUCCESS,
  RECEIVE_EVENTS_ERROR,
  RECEIVE_EVENTS_SUCCESS,
  REQUEST_CALENDARS,
  REQUEST_EVENTS,
  SET_APPSPACE,
  SET_APP_SETTINGS,
  SET_AUTH_USER,
} from './constants';
import merge from 'lodash/merge';
import moment from 'moment';

export const authorizationReducer = (state = null, action) => {
  if (action.type === SET_AUTH_USER) {
    return action.payload;
  }
  return state;
};

const entitiesInitialState = {};

export const entitiesReducer = (state = entitiesInitialState, action) => {
  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }
  return state;
};

// Calendars
export const calendarsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CALENDARS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CALENDARS_SUCCESS:
      return {
        ...state,
        ids: action.payload.ids,
        isFetching: false,
        error: null,
      };
    case RECEIVE_CALENDARS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

// Loading

export const loading = (state = 0, action) => {
  switch (action.type) {
    case LOADING_ADD:
      return state + 1;
    case LOADING_REMOVE:
      return state - 1;
    default:
      return state;
  }
};

// Appspace
export const appspaceReducer = (state = null, action) => {
  if (action.type === SET_APPSPACE) {
    return action.payload.appspace;
  }
  return state;
};

// App Settings
export const appSettingsReducer = (state = {}, action) => {
  if (action.type === SET_APP_SETTINGS) {
    return {
      ...state,
      ...action.payload.appSettings,
    };
  }
  return state;
};

// Events
export const eventsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_EVENTS: {
      const {
        payload: { dateRange },
      } = action;
      return dateRange.reduce(
        (acc, date) => {
          acc[date] = {
            ...state[date],
            isFetching: true,
          };
          return acc;
        },
        { ...state },
      );
    }
    case RECEIVE_EVENTS_SUCCESS: {
      const {
        payload: { dateRange, events },
      } = action;
      const newState = dateRange.reduce((acc, date) => {
        acc[date] = {
          ...state[date],
          events: [],
          isFetching: false,
        };
        return acc;
      }, {});
      events.forEach((event) => {
        event.dates.forEach((date) => {
          const formatedDate = moment(date.from).format('YYYY-MM-DD');
          if (formatedDate in newState) {
            newState[formatedDate].events.push(event._id);
          }
        });
      });
      return { ...state, ...newState };
    }
    case RECEIVE_EVENTS_ERROR: {
      const {
        payload: { dateRange },
      } = action;
      return dateRange.reduce(
        (acc, date) => {
          acc[date] = {
            ...state[date],
            isFetching: false,
          };
          return acc;
        },
        { ...state },
      );
    }
    default:
      return state;
  }
};
