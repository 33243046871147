import { connect } from 'react-redux';
import { setAppSettings } from '../../containers/App/actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

const UrlParser = (WrappedComponent) => {
  class UrlParserHoc extends Component {
    static propTypes = {
      children: PropTypes.node,
      dispatch: PropTypes.func.isRequired,
      location: PropTypes.shape({
        search: PropTypes.string.isRequired,
        query: PropTypes.shape({
          calendars: PropTypes.string,
          dateFrom: PropTypes.string,
          dateTo: PropTypes.string,
          fullscreen: PropTypes.string,
          view: PropTypes.string,
        }).isRequired,
      }).isRequired,
    };

    static defaultProps = {
      children: null,
    };

    constructor(props) {
      super(props);
      this.parseUrl = this.parseUrl.bind(this);
    }

    componentDidMount() {
      this.parseUrl(this.props);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.location.search !== this.props.location.search) {
        this.parseUrl(nextProps);
      }
    }

    parseUrl(props) {
      const {
        location: { query },
        dispatch,
      } = props;
      const { calendars, dateFrom, dateTo, fullscreen, view } = query;
      const appSettings = {
        calendars: [],
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().add(42, 'days').format('YYYY-MM-DD'),
        fullscreen: true,
        view: 'list',
      };
      if (calendars && calendars !== '') {
        appSettings.calendars = calendars.split('|');
      }
      const viewTypes = ['month', 'week', 'list'];
      if (view && viewTypes.includes(view)) {
        appSettings.view = view;
      }
      const fullscreenType = ['true', 'false'];
      if (fullscreen && fullscreenType.includes(fullscreen)) {
        appSettings.fullscreen = fullscreen === 'true';
      }
      if (dateFrom) {
        const dateFromMoment = moment(dateFrom);
        if (dateFromMoment.isValid())
          appSettings.dateFrom = dateFromMoment.format('YYYY-MM-DD');
      }
      if (dateTo) {
        const dateToMoment = moment(dateTo);
        if (dateToMoment.isValid()) {
          const dateFromMoment = moment(appSettings.dateFrom);
          appSettings.dateTo =
            dateToMoment.diff(dateFromMoment) <= 0
              ? dateFromMoment.format('YYYY-MM-DD')
              : dateToMoment.format('YYYY-MM-DD');
        }
      }
      dispatch(setAppSettings(appSettings));
    }

    render() {
      const { children } = this.props;
      return <WrappedComponent>{children}</WrappedComponent>;
    }
  }
  return connect()(UrlParserHoc);
};

export default UrlParser;
