import {
  LOADING_ADD,
  LOADING_REMOVE,
  RECEIVE_CALENDARS_ERROR,
  RECEIVE_CALENDARS_SUCCESS,
  RECEIVE_CALENDAR_GROUPS_SUCCESS,
  RECEIVE_CODELIST_SUCCESS,
  RECEIVE_EVENTS_ERROR,
  RECEIVE_EVENTS_SUCCESS,
  REQUEST_CALENDARS,
  REQUEST_EVENTS,
  SET_APPSPACE,
  SET_APP_SETTINGS,
  SET_AUTH_USER,
} from './constants';
import {
  appSettingsSelector,
  appspaceSelector,
  currentPathnameSelector,
} from './selectors';
import { calendarSchema, eventSchema } from '../../schemas';
import { getRangeOfDates, serializeQueryParams } from '../../utilities';
import { normalize } from 'normalizr';
import { push } from 'react-router-redux';
import moment from 'moment';

// Logged user
export const setAuthUser = (user) => ({
  type: SET_AUTH_USER,
  payload: user,
});

// Calendars
export const requestCalendars = () => ({
  type: REQUEST_CALENDARS,
  payload: {},
});

export const receiveCalendarsSuccess = (ids, entities) => ({
  type: RECEIVE_CALENDARS_SUCCESS,
  payload: {
    ids,
    entities,
  },
});

export const receiveCalendarsError = (error) => ({
  type: RECEIVE_CALENDARS_ERROR,
  payload: {
    error,
  },
});

// Groups
export const receiveCalendarGroupsSuccess = (ids, entities) => ({
  type: RECEIVE_CALENDAR_GROUPS_SUCCESS,
  payload: {
    ids,
    entities,
  },
});

export const loadCalendars = () => {
  return async (dispatch, getState, { Api }) => {
    dispatch(requestCalendars());
    dispatch(addLoading());
    try {
      const appspace = appspaceSelector(getState());
      const data = await Api.getCalendarsPublic(appspace);
      const { groups } = await Api.getPublicCalendarGroups(appspace);
      const normalized = normalize(data.calendars, [calendarSchema]);
      dispatch(removeLoading());
      dispatch(receiveCalendarsSuccess(normalized.result, normalized.entities));
      dispatch(
        receiveCalendarGroupsSuccess(groups, {
          groups: groups.reduce((acc, g) => ({ ...acc, [g]: { name: g } }), {}),
        }),
      );
      // if url does not contain calendars -> all calendars ale selected
      const appSettings = appSettingsSelector(getState());

      if ((appSettings.calendars || []).length === 0) {
        dispatch(setAppSettings({ calendars: normalized.result }));
      }
    } catch (error) {
      dispatch(removeLoading());
      dispatch(receiveCalendarsError(error));
    }
  };
};

// Loading

export const addLoading = () => {
  return {
    type: LOADING_ADD,
  };
};
export const removeLoading = () => {
  return {
    type: LOADING_REMOVE,
  };
};

// App space
export const setAppspace = (appspace) => ({
  type: SET_APPSPACE,
  payload: {
    appspace,
  },
});

// Events
export const requestEvents = (dateRange) => ({
  type: REQUEST_EVENTS,
  payload: {
    dateRange,
  },
});

export const receiveEventsSuccess = (dateRange, events, entities) => ({
  type: RECEIVE_EVENTS_SUCCESS,
  payload: {
    dateRange,
    events,
    entities,
  },
});

export const receiveEventsError = (dateRange, error) => ({
  type: RECEIVE_EVENTS_ERROR,
  payload: {
    dateRange,
    error,
  },
});

export const loadEvents = (
  dateFrom = moment().format('YYYY-MM-DD'),
  dateTo = moment(dateFrom).add(30, 'days').format('YYYY-MM-DD'),
) => {
  return async (dispatch, getState, { Api }) => {
    const dateRange = getRangeOfDates(dateFrom, dateTo);
    dispatch(addLoading());
    dispatch(requestEvents(dateRange));
    try {
      const appspace = appspaceSelector(getState());
      const data = await Api.getAppEventsPublic(appspace, {
        dateFrom,
        dateTo,
      });
      const normalized = normalize(data.events, [eventSchema]);
      dispatch(
        receiveEventsSuccess(dateRange, data.events, normalized.entities),
      );
      dispatch(removeLoading());
    } catch (error) {
      dispatch(receiveEventsError(dateRange, error));
      dispatch(removeLoading());
    }
  };
};

// App settings
export const setAppSettings = (appSettings) => ({
  type: SET_APP_SETTINGS,
  payload: {
    appSettings,
  },
});

// Links
export const changeUrl = (pathname, params, state = {}) =>
  push({
    pathname,
    search: serializeQueryParams(params),
    state,
  });

export const changeView = (view = 'list') => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    dispatch(changeUrl(pathname, { ...appSettings, view }));
  };
};

export const toggleFullscreen = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    dispatch(
      changeUrl(pathname, {
        ...appSettings,
        fullscreen: !appSettings.fullscreen,
      }),
    );
  };
};

export const changeCalendars = (calendarId = '') => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    let newCalendars = [...(appSettings.calendars || [])];

    if (newCalendars.includes(calendarId)) {
      newCalendars = newCalendars.filter((id) => id !== calendarId);
    } else {
      newCalendars.push(calendarId);
    }
    dispatch(changeUrl(pathname, { ...appSettings, calendars: newCalendars }));
  };
};

export const checkAll = (checked, calendars) => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    let calendarsId = [];
    if (checked) {
      calendarsId = calendars.map((c) => c.calendarId);
    }
    dispatch(changeUrl(pathname, { ...appSettings, calendars: calendarsId }));
  };
};

export const openEventDetail = (id) => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    dispatch(changeUrl(`/events/${id}`, appSettings));
  };
};

export const closeEventDetail = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    dispatch(changeUrl(`/events`, appSettings));
  };
};

export const nextPage = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    const dateFromMoment = moment(appSettings.dateFrom);
    switch (appSettings.view) {
      case 'week':
        appSettings.dateFrom = dateFromMoment
          .add(1, 'week')
          .format('YYYY-MM-DD');
        break;
      default:
        appSettings.dateFrom = dateFromMoment
          .add(1, 'month')
          .format('YYYY-MM-DD');
        break;
    }
    appSettings.dateTo = moment(appSettings.dateFrom)
      .add(1, 'month')
      .format('YYYY-MM-DD');
    dispatch(changeUrl(pathname, appSettings));
  };
};

export const prevPage = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    const dateFromMoment = moment(appSettings.dateFrom);
    switch (appSettings.view) {
      case 'week':
        appSettings.dateFrom = dateFromMoment
          .subtract(1, 'week')
          .format('YYYY-MM-DD');
        break;
      default:
        appSettings.dateFrom = dateFromMoment
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
        break;
    }
    appSettings.dateTo = moment(appSettings.dateFrom)
      .add(1, 'month')
      .format('YYYY-MM-DD');
    dispatch(changeUrl(pathname, appSettings));
  };
};

export const todayPage = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    const dateFrom = moment().format('YYYY-MM-DD');
    const dateTo =
      moment(appSettings.dateTo).diff(moment()) >= 0
        ? appSettings.dateTo
        : moment().add(1, 'month').endOf('month').format('YYYY-MM-DD');
    dispatch(
      changeUrl(
        pathname,
        { ...appSettings, dateFrom, dateTo },
        { today: true },
      ),
    );
  };
};

export const resetToday = () => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    dispatch(changeUrl(pathname, appSettings));
  };
};

export const pickDateFrom = (momentDate) => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    dispatch(
      changeUrl(pathname, {
        ...appSettings,
        dateFrom: moment(momentDate).format('YYYY-MM-DD'),
      }),
    );
  };
};

export const pickDateTo = (momentDate) => {
  return (dispatch, getState) => {
    const appSettings = appSettingsSelector(getState());
    const pathname = currentPathnameSelector(getState());
    dispatch(
      changeUrl(pathname, {
        ...appSettings,
        dateTo: moment(momentDate).format('YYYY-MM-DD'),
      }),
    );
  };
};

export const receiveCodelistSuccess = (codelist, data) => ({
  type: RECEIVE_CODELIST_SUCCESS,
  payload: {
    entities: {
      codelists: {
        [codelist]: data,
      },
    },
  },
});

// codelist
export const loadCodelist = (codelist) => {
  return async (dispatch, getState, { CoreApi }) => {
    try {
      const data = await CoreApi.getCodelist(codelist);
      dispatch(receiveCodelistSuccess(codelist, data));
    } catch (error) {
      console.error(error);
    }
  };
};
