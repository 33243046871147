import { rem } from 'polished';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)};
  height: 100%;
`;

const NotFoundContent = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotFoundText = styled.div`
  margin-top: ${rem(20)};
  text-align: center;
  font-size: ${rem(24)};
  font-weight: 700;
  color: ${({ theme }) => theme.color.notImportant};
`;
const NotFound = (props) => {
  const { text, theme } = props;
  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <Icon name="file" width={50} color={theme.color.notImportant} />
        <NotFoundText>{text}</NotFoundText>
      </NotFoundContent>
    </NotFoundWrapper>
  );
};

NotFound.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      notImportant: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withTheme(NotFound);
