import PropTypes from 'prop-types';

export const SmarttagType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
});

export const SmarttagsType = PropTypes.arrayOf(SmarttagType);

export const EventType = PropTypes.shape({
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ),
  perex: PropTypes.string,
  title_photo: PropTypes.shape({
    media_url: PropTypes.string,
    public_url: PropTypes.string,
    uri: PropTypes.string,
    dimensions: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      W: PropTypes.number.isRequired,
      H: PropTypes.number.isRequired,
    }),
  }),
  color: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  link: PropTypes.string,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
  calendarId: PropTypes.string,
  appspace: PropTypes.string,
  smarttags: SmarttagsType,
});

export const CalendarType = PropTypes.shape({
  _id: PropTypes.string,
  public: PropTypes.bool,
  color: PropTypes.string,
  name: PropTypes.string,
  calendarId: PropTypes.string,
  appspace: PropTypes.string,
});

export const DatesType = PropTypes.objectOf(PropTypes.arrayOf(EventType));

export const CalendarArrayType = PropTypes.arrayOf(CalendarType);

export const AppSettingsType = PropTypes.shape({
  calendars: PropTypes.arrayOf(PropTypes.string),
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  fullscreen: PropTypes.bool,
  view: PropTypes.string,
});

export const DateRangeType = PropTypes.shape({
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
});

export const FullscreenType = PropTypes.bool;

export const CurrentCalendarsType = PropTypes.arrayOf(PropTypes.string);

export const ViewType = PropTypes.oneOf(['month', 'week', 'list']);
