import { correctDates, getProp, getRangeOfDates } from '../../utilities';
import { createSelector } from 'reselect';
import { currentEventIdSelector } from '../EventDetail/selectors';
import moment from 'moment';

// Entity
export const entitiesDomainSelector = (state) => state.entities;

export const entitiesTypeSelector = (type) =>
  createSelector(entitiesDomainSelector, (substate) =>
    getProp(substate, [type], {}),
  );

export const entitySelector = (type, id) =>
  createSelector(entitiesTypeSelector(type), (byId) => getProp(byId, [id], {}));

// Appspace
export const appspaceSelector = (state) => state.appspace;

// Loading

export const loadingSelector = (state) => state.loading;

// Calendars
const calendarsDomainSelector = (state) => state.calendars || [];

export const calendarsSelector = createSelector(
  entitiesTypeSelector('calendars'),
  (calendars) => {
    return Object.keys(calendars).map((key) => calendars[key]) || [];
  },
);

export const calendarGroupsSelector = createSelector(
  entitiesTypeSelector('groups'),
  (groups) => {
    return Object.keys(groups).map((key) => groups[key].name);
  },
);

export const calendarColorMapSelector = createSelector(
  entitiesTypeSelector('calendars'),
  (calendars) => {
    return Object.keys(calendars).reduce((acc, key) => {
      acc[key] = calendars[key].color;
      return acc;
    }, {});
  },
);

export const calendarsIsFetchingSelector = createSelector(
  calendarsDomainSelector,
  (substate) => getProp(substate, ['isFetching'], false),
);

// App settings
export const appSettingsSelector = (state) => state.appSettings;

export const dateRangeSelector = createSelector(
  appSettingsSelector,
  (settings) => correctDates(settings),
);

export const dateRangeArraySelector = createSelector(
  dateRangeSelector,
  ({ dateFrom, dateTo }) => getRangeOfDates(dateFrom, dateTo),
);

export const currentCalendarsSelector = createSelector(
  appSettingsSelector,
  (settings) => settings.calendars || [],
);

export const fullscreenSelector = createSelector(
  appSettingsSelector,
  (settings) => getProp(settings, ['fullscreen'], true),
);

export const viewSelector = createSelector(
  appSettingsSelector,
  (settings) => settings.view || 'list',
);

export const currentDateFrom = createSelector(
  appSettingsSelector,
  (settings) => settings.dateFrom || moment().format('YYYY-MM-DD'),
);

export const currentDateTo = createSelector(
  appSettingsSelector,
  (settings) =>
    settings.dateTo || moment().add(1, 'month').format('YYYY-MM-DD'),
);

// Events
const eventsByDateDomainSelector = (state) => state.eventsByDate;

export const eventsIsFetchingSelector = createSelector(
  eventsByDateDomainSelector,
  dateRangeArraySelector,
  (byDate, dateRange) => {
    let isFetching = false;
    for (let i = 0; i < dateRange.length; i++) {
      const date = dateRange[i];
      if (isFetching) break;
      isFetching = isFetching || getProp(byDate, [date, 'isFetching'], false);
    }
    return isFetching;
  },
);

export const eventsSelector = createSelector(
  eventsByDateDomainSelector,
  entitiesTypeSelector('events'),
  dateRangeArraySelector,
  currentCalendarsSelector,
  calendarColorMapSelector,
  (eventsByDate, eventsEntities, dateRange, currentCalendars, colorMap) => {
    return dateRange.reduce((acc, date) => {
      const dateObject = eventsByDate[date] || {};
      const eventIds = getProp(dateObject, ['events'], []);
      const dateIndexMap = {};
      const events = eventIds.reduce((eventsAcc, eventId) => {
        const event = getProp(eventsEntities, [eventId], {});
        const currentDateIndex =
          dateIndexMap[eventId] === undefined
            ? (dateIndexMap[eventId] = 0)
            : (dateIndexMap[eventId] += 1);
        if (currentCalendars.includes(event.calendarId)) {
          const eventDates = event.dates.filter((eventDate) =>
            moment(eventDate.from).isSame(date, 'day'),
          );
          const dateFrom = moment(eventDates[currentDateIndex].from);
          const dateTo = moment(eventDates[currentDateIndex].to);
          const timeFrom =
            Number.parseInt(dateFrom.format('H'), 10) * 60 +
            Number.parseInt(dateFrom.format('m'), 10);
          const timeTo =
            Number.parseInt(dateTo.format('H'), 10) * 60 +
            Number.parseInt(dateTo.format('m'), 10);
          eventsAcc.push({
            ...event,
            color: colorMap[event.calendarId] || null,
            dateFrom: eventDates[currentDateIndex].from,
            dateTo: eventDates[currentDateIndex].to,
            allDayEvent: !!eventDates[currentDateIndex].allDayEvent,
            timeFrom,
            timeTo,
            duration: timeTo - timeFrom,
          });
        }
        return eventsAcc;
      }, []);

      // zoradime eventy podla zaciatku
      events.sort((a, b) => {
        return a.timeFrom - b.timeFrom;
      });
      return { ...acc, [date]: events };
    }, {});
  },
);

export const eventsByMonthsSelector = createSelector(
  eventsSelector,
  (substate) =>
    Object.keys(substate).reduce((p, next) => {
      const month = moment(next).format('YYYY-MM');
      if (p[month]) {
        return {
          ...p,
          [month]: { ...p[month], [next]: substate[next] },
        };
      }
      return {
        ...p,
        [month]: {
          [next]: substate[next],
        },
      };
    }, {}),
);

// Pathname
export const currentPathnameSelector = createSelector(
  appspaceSelector,
  currentEventIdSelector,
  (appspace, eventId) => `/events${eventId ? `/${eventId}` : ''}`,
);

// User auth
const authorizationDomain = (state) => state.authorization;

export const isLoggedInSelector = createSelector(
  authorizationDomain,
  (domain) => domain !== null,
);

export const loggedInUserSelector = createSelector(
  authorizationDomain,
  (domain) => (domain !== null ? domain.user : null),
);

export const codelistsSelector = createSelector(
  entitiesDomainSelector,
  (substate) => substate.codelists || {},
);

export const codelistSelector = createSelector(
  codelistsSelector,
  (_, type) => type,
  (byId, id) => {
    if (id in byId) {
      return byId[id].codelist;
    }
    return null;
  },
);

export const codelistLabelByValueSelector = createSelector(
  codelistSelector,
  (codelist) => {
    if (!codelist) {
      return {};
    }
    return codelist.reduce((acc, c) => {
      acc[c.value] = c.label || c.value;
      return acc;
    }, {});
  },
);
