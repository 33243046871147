import { SmarttagsType } from '../../propTypes';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import sportnetUITheme from '@sportnet/ui/Themes/baseTheme';

const Smarttags = ({ items }) => {
  return (
    <ThemeProvider theme={sportnetUITheme}>
      <SmarttagTags items={items} disabled />
    </ThemeProvider>
  );
};

Smarttags.propTypes = {
  items: SmarttagsType.isRequired,
};

export default React.memo(Smarttags);
