import * as styledComponents from 'styled-components';
import baseTheme from '@sportnet/ui/Themes/baseTheme';

export const sportnetUiTheme = {
  ...baseTheme,
  table: baseTheme.table,
  tooltip: baseTheme.tooltip,
  separatorColor: baseTheme.separatorColor,
  color: {
    primary: baseTheme.color.primary,
    fadedText: baseTheme.color.fadedText,
    primaryBg: '#fff',
  },
  inactiveColor: baseTheme.inactiveColor,
  grid: {
    gutterWidth: baseTheme.grid.gutterWidth,
  },
  baseBorderRadius: baseTheme.baseBorderRadius,
  borderRadius: baseTheme.baseBorderRadius,
  largeBorderRadius: baseTheme.largeBorderRadius,
  responsiveMenuPrimary: baseTheme.responsiveMenuPrimary,
  responsiveMenu: baseTheme.responsiveMenu,
  textColor: baseTheme.textColor,
  fontFamily: "'Open Sans', sans-serif",
  form: baseTheme.form,
  input: baseTheme.input,
  formGroup: baseTheme.formGroup,
};

const { ThemeProvider } = styledComponents;

export { ThemeProvider };
