import * as React from 'react';
import { ThemeProvider } from '@sportnet/ui/Themes/styled-components';
import { __ } from '../../utilities';
import Api from '../../Api';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Input from '@sportnet/ui/Input';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import PropTypes from 'prop-types';
import baseTheme from '@sportnet/ui/Themes/baseTheme';
import moment from 'moment';

const ShareModal = ({ appspace, calendar, onClose }) => {
  const [fetching, setFetching] = React.useState(false);
  const [token, setToken] = React.useState('');

  // generate link
  const currentDate = moment().format('YYYY-MM-DD');
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const baseUrl = apiUrl.slice(apiUrl.indexOf('://') + 3); // trim protocol
  const webcalLink = `webcal://${baseUrl}/public/${appspace}/export/events?calendarId=${
    calendar ? calendar.calendarId : ''
  }&dateFrom=${currentDate}&token=${token}`;
  const link = `${
    process.env.REACT_APP_API_BASE_URL
  }/public/${appspace}/export/events?calendarId=${
    calendar ? calendar.calendarId : ''
  }&dateFrom=${currentDate}&token=${token}`;

  React.useEffect(() => {
    if (calendar) {
      (async () => {
        setToken('');
        setFetching(true);
        try {
          const response = await Api.createCalendarAccessToken(
            appspace,
            calendar.calendarId,
          );
          setToken(response.token);
        } catch (e) {
          await alert(__('Pri vytváraní odkazu nastala chyba'));
          onClose();
        } finally {
          setFetching(false);
        }
      })();
    }
  }, [calendar, appspace, onClose]);

  return (
    <Modal isOpen={!!calendar} handleClose={onClose}>
      {fetching ? (
        <Loader />
      ) : (
        <>
          {calendar && (
            <>
              <ModalContent>
                <Header>
                  {__('Zdieľanie kalendára')} "{calendar.name}"
                </Header>
                <Header size="s">{__('Priamo do e-mailového klienta')}</Header>
                <a href={webcalLink}>
                  <Button primary basic>
                    {__('Pridať do kalendára')}
                  </Button>
                </a>
                <br />
                <br />
                <Header size="s">{__('Alebo si skopírujte odkaz:')}</Header>
                <ThemeProvider theme={baseTheme}>
                  <Input id="calendar_id" value={link}>
                    <input />
                    <Input.Button
                      primary
                      onClick={(e) => {
                        e.preventDefault();
                        const copyText = document.getElementById('calendar_id');
                        copyText.select();
                        copyText.setSelectionRange(0, copyText.value.length);
                        document.execCommand('copy');
                        alert(__('Odkaz bol skopírovaný'));
                      }}
                    >
                      {__('Skopírovať odkaz')}
                    </Input.Button>
                  </Input>
                </ThemeProvider>
              </ModalContent>
              <ModalActions>
                <span></span>
                <Button onClick={onClose}>{__('Zavrieť')}</Button>
              </ModalActions>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

ShareModal.propTypes = {
  appspace: PropTypes.string.isRequired,
  calendar: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
