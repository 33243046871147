import {
  RECEIVE_MORE_EVENTS,
  REQUEST_MORE_EVENTS,
  SET_SCROLL_TOP,
} from './constants';
import { currentDateTo } from '../App/selectors';
import { loadEvents, setAppSettings } from '../App/actions';
import moment from 'moment';

export const setListViewScrollTop = (scrollTop) => ({
  type: SET_SCROLL_TOP,
  payload: {
    scrollTop,
  },
});

export const receiveMoreEvents = () => ({
  type: RECEIVE_MORE_EVENTS,
  payload: {},
});

export const requestMoreEvents = () => ({
  type: REQUEST_MORE_EVENTS,
  payload: {},
});

export const loadMoreEvents = () => {
  return async (dispatch, getState) => {
    const oldDateTo = currentDateTo(getState());
    const newDateTo = moment(oldDateTo)
      .add(2, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    dispatch(requestMoreEvents());
    try {
      await dispatch(loadEvents(oldDateTo, newDateTo));
      dispatch(setAppSettings({ dateTo: newDateTo }));
      dispatch(receiveMoreEvents());
    } catch (error) {
      dispatch(receiveMoreEvents());
    }
  };
};
