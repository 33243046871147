import * as React from 'react';
import {
  ThemeProvider as SportnetUiThemeProvider,
  sportnetUiTheme,
} from '../../utilities/sportnetUiTheme';
import { ThemeProvider } from 'styled-components';
import { __, getDomain } from '../../utilities';
import {
  appSettingsDidFailSelector,
  appSettingsIsFetchingSelector,
  appSettingsSelector,
} from './selectors';
import { connect } from 'react-redux';
import { getTheme } from '@sportnet/ui/Themes/baseTheme';
import { loadAppSettings } from './actions';
import { setAppSettings } from '../App/actions';
import Context from './context';
import GlobalStyles from '../../globalStyles';
import Icon from '../../components/Icon';
import NotFound from '../../components/NotFound';
import styled from 'styled-components';

const LoadingIcon = styled(Icon)`
  display: flex;
  justify-content: center;
`;

export const isStandalone =
  typeof window !== 'undefined' && window.location === window.parent.location;

const mapStateToProps = (state) => {
  return {
    appSettings: appSettingsSelector(state),
    isFetching: appSettingsIsFetchingSelector(state),
    didFail: appSettingsDidFailSelector(state),
  };
};

class DomainResolver extends React.PureComponent {
  static async getInitialProps(props) {
    const { dispatch } = props;
    const domain = getDomain(props);

    return dispatch(loadAppSettings(domain));
  }

  constructor(props) {
    super(props);
    this.state = {
      editModeEnabled: false,
    };
    if (
      !isStandalone &&
      typeof window !== 'undefined' &&
      typeof window.addEventListener !== 'undefined'
    ) {
      window.addEventListener('message', this.handleMessage);
      this.postMessage('READY');
    }
  }

  async componentDidMount() {
    if (isStandalone) {
      try {
        await DomainResolver.getInitialProps(this.props);
      } catch (e) {
        /* */
      }
    }
  }

  postMessage = (type, payload = {}) => {
    window.parent.postMessage(
      {
        type,
        payload,
      },
      '*',
    );
  };

  handleMessage = (message) => {
    const { dispatch } = this.props;
    console.log('[vzdelavanie public] <<<', message.data);
    if (message.data.type === 'UPDATE_SETTINGS') {
      dispatch(setAppSettings({ settings: message.data.payload }));
    } else if (message.data.type === 'SET_EDIT_MODE') {
      this.setState({ editModeEnabled: message.data.payload });
    }
  };

  render() {
    const { appSettings, isFetching, didFail } = this.props;
    const { editModeEnabled } = this.state;

    const areSettingsLoaded = Object.keys(appSettings).length > 0;

    if (isFetching && !areSettingsLoaded) {
      return (
        <>
          <GlobalStyles />
          <LoadingIcon />
        </>
      );
    }

    if (!areSettingsLoaded || didFail) {
      return (
        <>
          <GlobalStyles />
          <NotFound text={__('Stránka neexistuje.')} />
        </>
      );
    }

    return (
      <Context.Provider
        value={{
          isStandalone,
          editModeEnabled,
          onClickArea: (id) => this.postMessage('CLICK_AREA', id),
        }}
      >
        <SportnetUiThemeProvider theme={sportnetUiTheme}>
          <ThemeProvider theme={getTheme()}>
            <>
              <GlobalStyles />
              {this.props.children}
            </>
          </ThemeProvider>
        </SportnetUiThemeProvider>
      </Context.Provider>
    );
  }
}

export default connect(mapStateToProps)(DomainResolver);
