import { Route, Router } from 'react-router';
import React from 'react';
// containers
import App from './containers/App';
import Authorization from './containers/Authorization';
import DomainResolver from './containers/DomainResolver';
import EventDetail from './containers/EventDetail';

const Routes = (props) => {
  return (
    <DomainResolver>
      <Router {...props}>
        <Route component={Authorization}>
          <Route path="/" component={App}>
            <Route path="/events/:eventId" component={EventDetail} />
          </Route>
          <Route path="/events" component={App}>
            <Route path="/:eventId" component={EventDetail} />
          </Route>
        </Route>
      </Router>
    </DomainResolver>
  );
};

export default Routes;
