import { extendMoment } from 'moment-range';
import moment from 'moment';

const Moment = extendMoment(moment);

export const __ = (toTranslate) => {
  return toTranslate;
};

export const getProp = (object, path, defaultValue = '') => {
  if (path === undefined || path === null) return defaultValue;
  let pathArray = path;
  if (typeof path === 'string') {
    pathArray = path.split('.');
  }
  const val = pathArray.reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

export const getRangeOfDates = (
  start,
  end,
  key = 'day',
  format = 'YYYY-MM-DD',
) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  if (endMoment.diff(startMoment) < 0) return [];
  return Array.from(Moment.range(startMoment, endMoment).by(key)).map((date) =>
    date.format(format),
  );
};

export const correctDates = (settings) => {
  let { dateTo, dateFrom } = settings;
  const originalDateFrom = dateFrom;
  switch (settings.view) {
    case 'week':
      dateFrom = moment(dateFrom).startOf('week').format('YYYY-MM-DD');
      dateTo = moment(originalDateFrom).endOf('week').format('YYYY-MM-DD');
      break;
    case 'month':
      dateFrom = moment(dateFrom)
        .startOf('month')
        .startOf('week')
        .format('YYYY-MM-DD');
      dateTo = moment(originalDateFrom)
        .endOf('month')
        .endOf('week')
        .format('YYYY-MM-DD');
      break;
    default:
      break;
  }
  return { dateTo, dateFrom };
};

export const serializeQueryParams = (parameters) => {
  const query = Object.keys(parameters)
    .map((key) => {
      let param = parameters[key];
      if (Array.isArray(param)) {
        param = param.join('|');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(param)}`;
    })
    .join('&');
  return `${query ? '?' : ''}${query}`;
};

export const formatVenue = (venue, countryLabelByValue = {}) => {
  if (!venue) {
    return '';
  }
  const result = [];
  if (venue.name) {
    result.push(venue.name);
  }
  if (venue.address) {
    const { number: streetNumber, street, city, zip, country } = venue.address;
    if (street) {
      let streetResult = street;
      if (streetNumber) {
        streetResult += ` ${streetNumber}`;
      }
      result.push(streetResult);
    }
    if (city || zip) {
      const cityResult = [];
      if (city) {
        cityResult.push(city);
      }
      if (zip) {
        cityResult.push(zip);
      }
      if (cityResult.length) {
        result.push(cityResult.join(' '));
      }
    }
    if (country) {
      const countryLabel =
        country in countryLabelByValue ? countryLabelByValue[country] : country;
      result.push(countryLabel);
    }
  }

  return result.join(', ');
};

export const getDomain = (props) => {
  let domain = '';
  if (props && props.hostname) {
    domain = props.hostname;
  }
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    domain = window.location.hostname;
  }
  return domain.replace(/\.(localhost|127\.0\.0\.1|::1)$/, '');
};
