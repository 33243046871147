import { __, formatVenue } from '../../utilities';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
`;

const NoEventsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(70)};
  flex-shrink: 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  color: ${({ theme }) => theme.color.notImportant};
  background: #fff;
  text-align: center;
`;

const EventWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: ${rem(15)};
  padding-left: 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  min-height: ${rem(70)};
  flex-shrink: 0;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.textColor};
  width: 100%;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const EventDate = styled.div`
  font-size: ${rem(24)};
  min-width: ${rem(26)};
  margin-right: 0;
  text-align: center;
  ${({ theme }) => theme.media.sm`
    margin-right:${rem(10)};
  `};
`;

const EventDay = styled.div`
  font-size: ${rem(12)};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const EventColor = styled.div`
  background: ${({ color }) => color};
  width: ${rem(12)};
  border-radius: 50%;
  height: ${rem(12)};
`;

const EventTitle = styled.div`
  font-size: ${rem(14)};
  margin-left: ${rem(5)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventDescription = styled.div`
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.color.notImportant};
  width: 100%;
`;

const EventMonth = styled.div`
  color: ${({ theme }) => theme.textColor};
  padding: ${rem(15)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  &::first-letter {
    text-transform: uppercase;
  }
  background-color: ${({ theme }) => theme.listViewHeaderBackgroundColor};
`;
const DateColumn = styled(Column)`
  width: ${rem(72)};
  flex-shrink: 0;
  margin: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.media.sm`
    margin-left: ${rem(20)};
    flex-direction: row;  
    width: ${rem(120)};
    justify-content: flex-start;  
  `};
`;

const Time = styled.span`
  margin: 0 ${rem(3)} 0 0;
  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

const Perex = styled.div`
  margin-top: ${rem(2)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeColumn = styled(Column)`
  max-width: ${rem(103)};
  align-items: center;
  font-size: ${rem(12)};
  margin-right: 0;
  display: none;
  ${({ theme }) => theme.media.sm`
    display: flex;
    margin-right: ${rem(41)};
    margin-left: ${rem(11)};
  `};
`;

const EventList = (props) => {
  const { eventsByDay, onClick, month, countryLabelByValue } = props;
  const monthWithYear = moment(month, 'YYYY-MM').format('MMMM YYYY');
  const numberOfEventsInMonth = Object.values(eventsByDay).reduce(
    (acc, events) => acc + events.length,
    0,
  );
  return (
    <Wrapper>
      <EventMonth>{monthWithYear}</EventMonth>
      {numberOfEventsInMonth === 0 && (
        <NoEventsWrapper>
          {__('Pre tento mesiac sa nenašli žiadne udalosti')}
        </NoEventsWrapper>
      )}
      {Object.keys(eventsByDay).map((day) => {
        if (eventsByDay[day].length > 0) {
          return eventsByDay[day].map((event, index) => {
            const {
              _id,
              allDayEvent,
              dateFrom,
              dateTo,
              title,
              color,
              perex,
              venue,
            } = event;
            const momentFrom = moment(dateFrom);
            const momentTo = moment(dateTo);
            let timeFormated = __('Celý deň');
            if (!allDayEvent) {
              timeFormated = `${momentFrom.format('HH:mm')} - ${momentTo.format(
                'HH:mm',
              )}`;
            }
            return (
              <EventWrapper
                key={`${_id}-${dateFrom}-${index.toString()}`}
                onClick={() => onClick(_id)}
              >
                <DateColumn>
                  {index === 0 && (
                    <Fragment>
                      <EventDate>{momentFrom.format('D')}</EventDate>
                      <EventDay>{momentFrom.format('dddd')}</EventDay>
                    </Fragment>
                  )}
                </DateColumn>
                <TimeColumn>{timeFormated}</TimeColumn>
                <Column>
                  <Row>
                    <EventColor color={color} />
                    <EventTitle>{title}</EventTitle>
                  </Row>
                  <Row>
                    <EventDescription>
                      {perex && <Perex>{perex}</Perex>}
                      <Time>{timeFormated}</Time>
                      {venue && (
                        <span>{formatVenue(venue, countryLabelByValue)}</span>
                      )}
                    </EventDescription>
                  </Row>
                </Column>
              </EventWrapper>
            );
          });
        }
        return null;
      })}
    </Wrapper>
  );
};

EventList.propTypes = {
  eventsByDay: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  month: PropTypes.string.isRequired,
  countryLabelByValue: PropTypes.shape({}).isRequired,
};

export default EventList;
