import { css } from 'styled-components';
import { rem } from 'polished';

const color = {
  base: '#555',
  primary: '#5283E7',
  notImportant: '#bbb',
};

const sizes = {
  lg: 1200,
  l: 1100,
  md: 992,
  m: 900,
  sm: 768,
  xsm: 700,
  s: 450,
  xs: 350,
};

const media = {
  xs: (...args) => css`
    @media (min-width: ${sizes.xs}px) {
      ${css(...args)};
    }
  `,
  s: (...args) => css`
    @media (min-width: ${sizes.s}px) {
      ${css(...args)};
    }
  `,
  xsm: (...args) => css`
    @media (min-width: ${sizes.xsm}px) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (min-width: ${sizes.sm}px) {
      ${css(...args)};
    }
  `,
  m: (...args) => css`
    @media (min-width: ${sizes.m}px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: ${sizes.md}px) {
      ${css(...args)};
    }
  `,
  l: (...args) => css`
    @media (min-width: ${sizes.l}px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: ${sizes.lg}px) {
      ${css(...args)};
    }
  `,
};

export const theme = {
  media,
  grid: {
    gutterWidth: 10,
  },
  color,
  borderColor: '#eee',
  background: '#f1f1f1',
  listViewHeaderBackgroundColor: '#F9F9F9',
  weekViewHeaderBackgroundColor: '#F9F9F9',
  monthViewHeaderBackgroundColor: '#F9F9F9',
  textColor: color.base,
  defaultEventColor: '#EDF2FD',
  borderRadiusSmall: rem(2),
  boxShadow: `0px ${rem(2)} ${rem(3)} 0 rgba(0, 0, 0, 0.1)`,
};
