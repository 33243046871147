import {
  CalendarArrayType,
  DateRangeType,
  DatesType,
  ViewType,
} from '../../propTypes';
import {
  appspaceSelector,
  calendarGroupsSelector,
  calendarsIsFetchingSelector,
  calendarsSelector,
  currentCalendarsSelector,
  currentDateFrom,
  currentDateTo,
  dateRangeSelector,
  eventsIsFetchingSelector,
  eventsSelector,
  fullscreenSelector,
  loadingSelector,
  viewSelector,
} from './selectors';
import {
  changeCalendars,
  changeView,
  checkAll,
  closeEventDetail,
  loadCalendars,
  loadCodelist,
  loadEvents,
  nextPage,
  pickDateFrom,
  pickDateTo,
  prevPage,
  todayPage,
} from './actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailSidebar from '../../components/DetailSidebar';
import Header from './Header';
import ListView from '../ListView';
import Loader from '../../components/Loader';
import MobileDetect from 'mobile-detect';
import MonthView from '../MonthView';
import NavSidebar from '../../components/NavSidebar';
import Overlay from '../../components/Overlay';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ShareModal from './ShareModal';
import WeekView from '../WeekView';
import styled from 'styled-components';
import withUrlParser from '../../hocs/UrlParser';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.background};
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      openedShareCalendar: null,
    };
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.handleClickPrevPage = this.handleClickPrevPage.bind(this);
    this.handleClickNextPage = this.handleClickNextPage.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleClickTodayPage = this.handleClickTodayPage.bind(this);
    this.handleClickCalendar = this.handleClickCalendar.bind(this);
    this.handleCloseSidebar = this.handleCloseSidebar.bind(this);
    const md = new MobileDetect(window.navigator.userAgent);
    this.isPhone = md.phone();
  }

  async componentDidMount() {
    const { dispatch, dateRange } = this.props;
    await dispatch(loadCalendars());
    await dispatch(loadCodelist('country'));
    await this.getEvents(dispatch, dateRange);
    if (this.isPhone) {
      this.handleChangeView('list');
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.search !== this.props.location.search ||
      (!this.props.dateRange.dateFrom && nextProps.dateRange.dateFrom) ||
      (!this.props.dateRange.dateTo && nextProps.dateRange.dateTo)
    ) {
      const { dispatch, dateRange } = nextProps;
      await this.getEvents(dispatch, dateRange);
    }
  }

  async getEvents(dispatch, { dateFrom, dateTo }) {
    await dispatch(loadEvents(dateFrom, dateTo));
  }

  handleChangeView(type) {
    const { dispatch } = this.props;
    dispatch(changeView(type));
  }
  handleClickPrevPage() {
    const { dispatch } = this.props;
    dispatch(prevPage());
  }

  handleClickNextPage() {
    const { dispatch } = this.props;
    dispatch(nextPage());
  }

  handleToggleSidebar() {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  handleChangeStartDate(date) {
    this.props.dispatch(pickDateFrom(date));
  }

  handleChangeEndDate(date) {
    this.props.dispatch(pickDateTo(date));
  }
  handleClickTodayPage() {
    this.props.dispatch(todayPage());
  }
  handleClickCalendar(calendarId) {
    this.props.dispatch(changeCalendars(calendarId));
  }
  handleClickCheckAll = (e, calendars) => {
    this.props.dispatch(checkAll(e.target.checked, calendars));
  };
  handleCloseSidebar() {
    this.setState({
      sidebarVisible: false,
    });
  }

  render() {
    const {
      children,
      calendars,
      calendarsIsFetching,
      events,
      view,
      dateFrom,
      dateTo,
      isFullScreen,
      selectedCalendars,
      loading,
      calendarGroups,
      appspace,
    } = this.props;
    const { sidebarVisible, openedShareCalendar } = this.state;

    let content = <div />;

    if (this.isPhone) {
      content = <ListView events={events} isFullScreen={isFullScreen} />;
    } else {
      switch (view) {
        case 'month':
          content = <MonthView events={events} isFullScreen={isFullScreen} />;
          break;
        case 'week':
          content = <WeekView events={events} isFullScreen={isFullScreen} />;
          break;
        default:
          content = <ListView events={events} isFullScreen={isFullScreen} />;
          break;
      }
    }

    return (
      <Wrapper>
        <Loader loading={loading} />
        <Header
          toggleSidebar={this.handleToggleSidebar}
          visible={sidebarVisible}
          isFullScreen={this.isPhone ? false : isFullScreen}
          dateFrom={dateFrom}
          dateTo={dateTo}
          date={dateFrom}
          onClickLeftButton={this.handleClickPrevPage}
          onClickRightButton={this.handleClickNextPage}
          onClickToday={this.handleClickTodayPage}
          onClickWeek={() => this.handleChangeView('week')}
          onClickMonth={() => this.handleChangeView('month')}
          onClickList={() => this.handleChangeView('list')}
          onChangeStartDate={this.handleChangeStartDate}
          onChangeEndDate={this.handleChangeEndDate}
          view={this.isPhone ? 'list' : view}
          isPhone={!!this.isPhone}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0',
            height: '100%',
          }}
        >
          {content}
          <Overlay
            visible={sidebarVisible || children !== null}
            onClick={() => {
              this.props.dispatch(closeEventDetail());
              this.handleCloseSidebar();
            }}
          />
          <DetailSidebar opened={children !== null}>{children}</DetailSidebar>
          <NavSidebar
            visible={sidebarVisible}
            onClose={this.handleCloseSidebar}
            calendars={calendars}
            isFetching={calendarsIsFetching}
            selectedCalendars={selectedCalendars}
            calendarGroups={calendarGroups}
            onClickCalendar={this.handleClickCalendar}
            onClickCheckAll={(e) => this.handleClickCheckAll(e, calendars)}
            isFullScreen={isFullScreen}
            appspace={appspace}
            onClickShareCalendar={(calendar) => {
              this.setState({ openedShareCalendar: calendar });
            }}
          />
        </div>
        <ShareModal
          appspace={appspace}
          calendar={openedShareCalendar}
          onClose={() => {
            this.setState({ openedShareCalendar: null });
          }}
        />
      </Wrapper>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  calendars: CalendarArrayType.isRequired,
  calendarsIsFetching: PropTypes.bool.isRequired,
  dateRange: DateRangeType.isRequired,
  events: DatesType.isRequired,
  view: ViewType.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  dateFrom: PropTypes.string.isRequired,
  selectedCalendars: PropTypes.arrayOf(PropTypes.string),
  dateTo: PropTypes.string.isRequired,
  loading: PropTypes.number.isRequired,
  calendarGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  appspace: PropTypes.string.isRequired,
};

App.defaultProps = {
  children: null,
  selectedCalendars: [],
};

const mapStateToProps = (state) => {
  return {
    calendars: calendarsSelector(state),
    calendarsIsFetching: calendarsIsFetchingSelector(state),
    eventsIsFetching: eventsIsFetchingSelector(state),
    events: eventsSelector(state),
    dateRange: dateRangeSelector(state),
    view: viewSelector(state),
    dateFrom: currentDateFrom(state),
    dateTo: currentDateTo(state),
    isFullScreen: fullscreenSelector(state),
    selectedCalendars: currentCalendarsSelector(state),
    loading: loadingSelector(state),
    calendarGroups: calendarGroupsSelector(state),
    appspace: appspaceSelector(state),
  };
};

const reduxConnected = connect(mapStateToProps)(App);
const routerConnected = withRouter(reduxConnected);
const urlParserConnected = withUrlParser(routerConnected);
export default urlParserConnected;
