/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          } else if (responseContentType.includes('application/pdf')) {
            return response.blob();
          } else if (responseContentType.includes('text/plain')) {
            return response.text();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   */
  getCalendarGroups(appspace, parameters = {}) {
    let path = '/admin/{appspace}/calendargroups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   */
  adminGetSettings(appspace, parameters = {}) {
    let path = '/admin/{appspace}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Edit Contentspace Settings
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} parameters.body -
   */
  adminEditSettings(appspace, parameters = {}) {
    let path = '/admin/{appspace}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   */
  publicGetSettings(appspace, parameters = {}) {
    let path = '/settings/{appspace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * New event is created with additional parameters - date of creation and user information(user_id, display_name)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} parameters.body - Event object
   */
  postAppEvent(appspace, parameters = {}) {
    let path = '/admin/{appspace}/events';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return array of events
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.dateFrom - if is not defined and dateTo is defined then set to current date-time
   * @param {} parameters.dateTo -
   * @param {} parameters.smarttags - choose events by smarttags, server expect smarttags in shape "key1:val1,val2,val3|key2:val1,val2,val3|..." where "|" split smarttags and "," split smarttag values
   * @param {} parameters.calendarId - calendar identification
   * @param {} appspace - App space identification
   */
  getAppEvents(appspace, parameters = {}) {
    let path = '/admin/{appspace}/events';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['calendarId'] !== 'undefined') {
      queryParameters['calendarId'] = parameters['calendarId'];
    }

    queryParameters['calendarId'] = this.transformParameter(
      queryParameters['calendarId'],
    );

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get event detail by EventId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} eventId - Event objectId
   */
  getEventDetail(appspace, eventId, parameters = {}) {
    let path = '/admin/{appspace}/events/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Existed event is modified with additional parameters - date of modification and user information(user_id, display_name)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} eventId - Event objectId
   * @param {} parameters.body - Event object
   */
  putAppEvent(appspace, eventId, parameters = {}) {
    let path = '/admin/{appspace}/events/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{eventId}', eventId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete event by EventId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} eventId - Event objectId
   */
  deleteAppEvent(appspace, eventId, parameters = {}) {
    let path = '/admin/{appspace}/events/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{eventId}', eventId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   */
  getPublicCalendarGroups(appspace, parameters = {}) {
    let path = '/public/{appspace}/calendargroups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return array of events
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.dateFrom - if is not defined and dateTo is defined then set to current date-time
   * @param {} parameters.dateTo -
   * @param {} parameters.smarttags - choose events by smarttags, server expect smarttags in shape "key1:val1,val2,val3|key2:val1,val2,val3|..." where "|" split smarttags and "," split smarttag values
   * @param {} parameters.calendarId - calendar identification
   * @param {} appspace -
   */
  getAppEventsPublic(appspace, parameters = {}) {
    let path = '/public/{appspace}/events';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['calendarId'] !== 'undefined') {
      queryParameters['calendarId'] = parameters['calendarId'];
    }

    queryParameters['calendarId'] = this.transformParameter(
      queryParameters['calendarId'],
    );

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return iCal file
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.dateFrom - if is not defined and dateTo is defined then set to current date-time
   * @param {} parameters.smarttags - choose events by smarttags, server expect smarttags in shape "key1:val1,val2,val3|key2:val1,val2,val3|..." where "|" split smarttags and "," split smarttag values
   * @param {} parameters.calendarId - calendar identification
   * @param {} parameters.token - calendar access token
   * @param {} appspace -
   */
  getAppEventsPublicICalFile(appspace, parameters = {}) {
    let path = '/public/{appspace}/export/events';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['calendarId'] !== 'undefined') {
      queryParameters['calendarId'] = parameters['calendarId'];
    }

    queryParameters['calendarId'] = this.transformParameter(
      queryParameters['calendarId'],
    );

    if (typeof parameters['token'] !== 'undefined') {
      queryParameters['token'] = parameters['token'];
    }

    queryParameters['token'] = this.transformParameter(
      queryParameters['token'],
    );

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get event detail by EventId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} eventId - Event objectId
   * @param {} appspace - App space identification
   */
  getEventDetailPublic(eventId, appspace, parameters = {}) {
    let path = '/public/{appspace}/events/{eventId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId);

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * App new calendar
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} parameters.body - Calendar object
   */
  postCalendar(appspace, parameters = {}) {
    let path = '/admin/{appspace}/calendars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return array of calendars
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.public - public or private calendars
   * @param {} appspace - App space identification
   */
  getCalendars(appspace, parameters = {}) {
    let path = '/admin/{appspace}/calendars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['public'] !== 'undefined') {
      queryParameters['public'] = parameters['public'];
    }

    queryParameters['public'] = this.transformParameter(
      queryParameters['public'],
    );

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Modify calendar by CalendarId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} calendarId - Calendar id
   * @param {} parameters.body - Calendar object
   */
  putCalendar(appspace, calendarId, parameters = {}) {
    let path = '/admin/{appspace}/calendars/{calendarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{calendarId}', calendarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete calendar by CalendarId with all events
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} calendarId - Calendar id
   */
  deleteCalendar(appspace, calendarId, parameters = {}) {
    let path = '/admin/{appspace}/calendars/{calendarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{calendarId}', calendarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Revoke all access tokens bound to this calendar
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} calendarId - Calendar id
   */
  revokeCalendarTokens(appspace, calendarId, parameters = {}) {
    let path = '/admin/{appspace}/calendars/{calendarId}/revoke';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{calendarId}', calendarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Invite user to manage this calendar by email address
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} calendarId - Calendar id
   * @param {} parameters.body -
   */
  inviteManager(appspace, calendarId, parameters = {}) {
    let path = '/admin/{appspace}/calendars/{calendarId}/invite-manager';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{calendarId}', calendarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} invitationId -
   */
  processManagerInvitationResponse(invitationId, parameters = {}) {
    let path = '/manager-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return array of public calendars
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace -
   */
  getCalendarsPublic(appspace, parameters = {}) {
    let path = '/public/{appspace}/calendars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * App new calendar token
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appspace - App space identification
   * @param {} calendarId - Calendar id
   */
  createCalendarAccessToken(appspace, calendarId, parameters = {}) {
    let path = '/public/{appspace}/calendars/{calendarId}/token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appspace}', appspace);

    path = path.replace('{calendarId}', calendarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };
