import { CalendarType, EventType } from '../../propTypes';
import { __, formatVenue, getProp } from '../../utilities';
import {
  appspaceSelector,
  codelistLabelByValueSelector,
  entitySelector,
} from '../App/selectors';
import { closeEventDetail } from '../App/actions';
import { connect } from 'react-redux';
import { darken, rem } from 'polished';
import {
  eventDetailErrorSelector,
  eventDetailIsFetchingSelector,
  eventDetailSelector,
} from './selectors';
import { loadEventDetail, setCurrentEventId } from './actions';
import ContentView from '../../components/ContentView';
import Icon from '../../components/Icon';
import MediaManagerImage from '@sportnet/ui/MediaManagerImage';
import NotFound from '../../components/NotFound';
import Perex from '../../components/Perex';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Smarttags from '../../components/Smarttags';
import moment from 'moment';
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: ${rem(20)};
  ${({ theme }) => theme.media.sm`
    padding: 0 0 ${rem(26)} 0;
  `};
`;

const Dates = styled.div``;

const Heading = styled.div`
  display: flex;
  color: ${({ color, theme }) => color || theme.defaultEventColor};
`;

const HeadingCircle = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: 50%;
  background: ${({ color, theme }) => color || theme.defaultEventColor};
  margin-right: ${rem(10)};
  flex-shrink: 0;
`;

const KalendarName = styled.div`
  word-break: break-word;
  margin-right: ${rem(10)};
`;

const IconWrapper = styled.div`
  margin-left: auto;
`;

const EventWrapper = styled.div`
  padding: ${rem(20)};
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  ${({ theme }) => theme.media.sm`
    padding: ${rem(20)} 0;
  `};
`;

const EventTitle = styled.div`
  font-size: ${rem(18)};
  color: ${({ theme }) => theme.textColor};
  margin-bottom: ${rem(15)};
  ${({ theme }) => theme.media.sm`
    font-size: ${rem(24)};    
  `};
`;

const EventTitlePhoto = styled(MediaManagerImage)`
  width: 100%;
  border-radius: ${rem(4)};
  margin-top: 0;
  ${({ theme }) => theme.media.sm`
    margin-top: ${rem(10)};    
  `};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(5)};
`;

const InfoItem = styled.div`
  margin-bottom: ${rem(15)};
  margin-right: ${rem(0)};
  display: flex;
  align-items: center;
  align-content: center;
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.textColor};
  ${({ theme }) => theme.media.sm`
    margin-right: ${rem(15)}; 
    font-size: ${rem(14)};   
  `};
`;

const StyledA = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${rem(300)};
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.4, theme.textColor)};
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${rem(10)};
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  display: flex;
  width: ${rem(16)};
  margin-left: auto;
`;

const InfoWrapperOnSmallScreens = styled(InfoWrapper)`
  display: flex;
  ${({ theme }) => theme.media.sm`
      display: none;
    `};
`;

const InfoWrapperOnSmallScreensDates = styled(InfoWrapperOnSmallScreens)`
  margin: 0;
`;

const LoadingIcon = styled(Icon)`
  display: flex;
  justify-content: center;
`;

const InfoWrapperOnBigScreens = styled(InfoWrapper)`
  display: none;
  ${({ theme }) => theme.media.sm`
      display: flex;
    `};
`;

const InfoWrapperOnBigScreensDates = styled(InfoWrapperOnBigScreens)`
  margin: 0;
`;

const Line = styled.div`
  width: 100%;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
`;

class EventDetail extends Component {
  static init(props) {
    const {
      dispatch,
      params: { eventId },
    } = props;
    dispatch(setCurrentEventId(eventId));
    dispatch(loadEventDetail(eventId));
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.handleClickClose = this.handleClickClose.bind(this);
  }

  componentDidMount() {
    EventDetail.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const {
      params: { eventId: nextEventId },
    } = nextProps;
    const {
      params: { eventId },
    } = this.props;
    if (nextEventId !== eventId) {
      EventDetail.init(nextProps);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentEventId(null));
  }

  handleClickClose() {
    const { dispatch } = this.props;
    dispatch(closeEventDetail());
  }

  render() {
    const {
      isFetching,
      eventDetail,
      theme,
      eventCalendar,
      error,
      countryLabelByValue,
    } = this.props;
    const dates = getProp(eventDetail, 'dates', []);
    const venue = getProp(eventDetail, 'venue', null);
    const link = getProp(eventDetail, 'link', null);
    const datesContent = (
      <Dates>
        {dates.map((date) => {
          const momentFrom = moment(date.from);
          const momentTo = moment(date.to);
          return (
            <InfoItem
              key={`detail-${momentFrom.format()}-${momentTo.format()}`}
            >
              <StyledIcon name="calendar" color={theme.color.icon} />
              {`
      ${momentFrom.format('DD.MMMM')} ${`${
                date.allDayEvent
                  ? ''
                  : `${momentFrom.format('LT')}  -  ${momentTo.format('LT')}`
              }`}
      `}
            </InfoItem>
          );
        })}
      </Dates>
    );

    const content = (
      <InfoWrapper>
        {dates.length === 1 && datesContent}
        {venue && (
          <InfoItem>
            <StyledIcon name="place" color={theme.color.icon} />
            {formatVenue(venue, countryLabelByValue)}
          </InfoItem>
        )}
        {link && (
          <InfoItem>
            <StyledIcon name="web" color={theme.color.icon} />
            <StyledA target="_blank" href={link}>
              {link}
            </StyledA>
          </InfoItem>
        )}
      </InfoWrapper>
    );

    // Event does not exist
    if (error) {
      return (
        <Wrapper>
          <Header>
            <Heading>
              <IconWrapper onClick={this.handleClickClose}>
                <CloseIcon name="close" />
              </IconWrapper>
            </Heading>
          </Header>
          <NotFound text={__('Udalosť sa nenašla')} />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>
          <Heading color={eventDetail.color}>
            {eventDetail.color && <HeadingCircle color={eventDetail.color} />}
            <KalendarName>{eventCalendar.name || ''}</KalendarName>
            <IconWrapper onClick={this.handleClickClose}>
              <CloseIcon name="close" />
            </IconWrapper>
          </Heading>
        </Header>
        <Line />
        <EventWrapper>
          <EventTitle>{eventDetail.title || ''}</EventTitle>
          {eventDetail.perex && <Perex text={eventDetail.perex} />}
          <InfoWrapperOnBigScreens>{content}</InfoWrapperOnBigScreens>
          <InfoWrapperOnBigScreensDates>
            {dates.length > 1 && datesContent}
          </InfoWrapperOnBigScreensDates>
          {eventDetail.title_photo && (
            <EventTitlePhoto
              picture={eventDetail.title_photo}
              naturalWidth={1000}
              naturalHeight={0}
            />
          )}
          <InfoWrapperOnSmallScreens>{content}</InfoWrapperOnSmallScreens>
          <InfoWrapperOnSmallScreensDates>
            {dates.length > 1 && datesContent}
          </InfoWrapperOnSmallScreensDates>
          {isFetching && !eventDetail.description ? (
            <LoadingIcon
              name="loading"
              color={theme.color.primary}
              width="150"
              height="150"
              borderWidth="6"
            />
          ) : (
            <ContentView items={eventDetail.description || []} />
          )}
          {eventDetail.smarttags && eventDetail.smarttags.length > 0 && (
            <Smarttags items={eventDetail.smarttags} />
          )}
        </EventWrapper>
      </Wrapper>
    );
  }
}

EventDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  eventDetail: EventType.isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }).isRequired,
  eventCalendar: CalendarType.isRequired,
  error: PropTypes.shape({}),
  countryLabelByValue: PropTypes.shape({}).isRequired,
};

EventDetail.defaultProps = {
  error: null,
};

const mapStateToProps = (state, props) => {
  const { eventId } = props.params;
  const eventDetail = eventDetailSelector(eventId)(state);
  const calendarId = getProp(eventDetail, ['calendarId'], '');
  return {
    eventDetail,
    isFetching: eventDetailIsFetchingSelector(eventId)(state),
    appSpace: appspaceSelector(state),
    error: eventDetailErrorSelector(eventId)(state),
    eventCalendar: entitySelector('calendars', calendarId)(state),
    countryLabelByValue: codelistLabelByValueSelector(state, 'country'),
  };
};

const themed = withTheme(EventDetail);
export default connect(mapStateToProps)(themed);
