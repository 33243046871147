import { __, getProp } from '../../utilities';
import { rem } from 'polished';
import Button from '../Button';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: ${rem(170)};
`;
const CustomOptionsWrapper = styled.div`
  position: absolute;
  z-index: 8;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: baseline;
`;

const DropdownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${rem(13)};
`;

const types = {
  month: {
    label: __('Mesačný'),
    value: 'month',
  },
  week: {
    label: __('Týždenný'),
    value: 'week',
  },
  list: {
    label: __('Zoznam'),
    value: 'list',
  },
};

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.handleClickSelected = this.handleClickSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.handleClose();
    }
  }

  handleClickSelected() {
    if (this.state.isOpen) {
      this.handleClose();
    } else {
      this.setState({
        isOpen: true,
      });
    }
  }
  handleClose() {
    this.setState({
      isOpen: false,
    });
  }

  handleClick(type) {
    this.handleClose();
    if (type === 'week') {
      this.props.onClickWeek();
    } else if (type === 'month') {
      this.props.onClickMonth();
    } else if (type === 'list') {
      this.props.onClickList();
    }
  }

  render() {
    const { selectedType } = this.props;
    const { isOpen } = this.state;
    return (
      <Wrapper ref={(e) => (this.dropdown = e)}>
        {' '}
        <StyledButton
          primary
          basic
          onClick={this.handleClickSelected}
          icon={`${selectedType}-view`}
        >
          {getProp(types, [selectedType, 'label'])}
        </StyledButton>
        <DropdownIcon name="dropdown-arrow" />
        {isOpen && (
          <CustomOptionsWrapper>
            {Object.keys(types).map((type) => (
              <StyledButton
                key={type}
                primary
                basic
                icon={`${getProp(types, [type, 'value'])}-view`}
                onClick={() => this.handleClick(type)}
              >
                {getProp(types, [type, 'label'])}
              </StyledButton>
            ))}
          </CustomOptionsWrapper>
        )}
      </Wrapper>
    );
  }
}

CustomSelect.propTypes = {
  onClickList: PropTypes.func.isRequired,
  onClickMonth: PropTypes.func.isRequired,
  onClickWeek: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
};

export default CustomSelect;
