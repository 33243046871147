import { DatesType } from '../../propTypes';
import { __ } from '../../utilities';
import { connect } from 'react-redux';
import { currentEventIdSelector } from '../EventDetail/selectors';
import { openEventDetail } from '../App/actions';
import { rem } from 'polished';
import Event from '../../components/Event';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

const ALL_DAY_HEIGHT_RATIO = 1.5;
const HOURS_IN_DAY = 24 + ALL_DAY_HEIGHT_RATIO;
const MINUTES_IN_DAY = 60 * HOURS_IN_DAY;
const COLUMN_WIDTH = 100 / 7;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${COLUMN_WIDTH}%;
  max-width: ${COLUMN_WIDTH}%;
  position: relative;
  border: ${rem(0.5)} solid ${({ theme }) => theme.borderColor};
`;

const TableHead = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  z-index: 1;
  padding: ${rem(15)};
  color: ${({ theme, currentDay }) =>
    currentDay ? theme.color.primary : theme.color.base};
  background: ${({ currentDay, theme }) =>
    currentDay ? theme.eventColor : theme.weekViewHeaderBackgroundColor};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  border-left: ${rem(0.5)} solid ${({ theme }) => theme.borderColor};
  border-right: ${rem(0.5)} solid ${({ theme }) => theme.borderColor};
  ${({ isFullScreen }) =>
    isFullScreen
      ? `
    height: ${rem(60)};
    flex-direction: row;
  `
      : `
  height: ${rem(73)};
  flex-direction: column;
  `};
`;

const Date = styled.div`
  font-size: ${rem(24)};
  ${({ isFullScreen }) =>
    isFullScreen
      ? `
    margin-right: ${rem(10)};
  `
      : `margin-right: 0;`};
`;

const Day = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
  ${({ isFullScreen }) =>
    isFullScreen
      ? `
  font-size: ${rem(14)};
  margin-right: ${rem(10)};
  `
      : `  
  font-size: ${rem(12)};
  margin-right: 0;
  `};
`;

const CurrentDay = styled.div`
  margin-left: auto;
  color: #fff;
  top: -${rem(15)};
  font-size: ${rem(12)};
  padding: ${rem(5)};
  border-radius: ${rem(2)};
  background: ${({ theme }) => theme.color.primary};
  ${({ isFullScreen }) =>
    isFullScreen
      ? `
    position: initial;
  `
      : `position: absolute;

  `};
`;

const TableRow = styled.div`
  background: #fff;
  height: ${`${(100 * (MINUTES_IN_DAY / HOURS_IN_DAY)) / MINUTES_IN_DAY}%`};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.borderColor};
  width: 100%;
  position: absolute;
  ${({ idx }) => {
    const height = (100 * (MINUTES_IN_DAY / HOURS_IN_DAY)) / MINUTES_IN_DAY;
    const top = (idx - 1) * height;
    const firstRow =
      (100 * (MINUTES_IN_DAY / HOURS_IN_DAY) * ALL_DAY_HEIGHT_RATIO) /
      MINUTES_IN_DAY;
    return css`
      top: ${idx === 0 ? 0 : firstRow + top}%;
    `;
  }};
`;

const ColumnContent = styled.div`
  height: 100%;
  position: relative;
  & > ${TableRow}:first-child {
    height: ${`${
      (100 * (MINUTES_IN_DAY / HOURS_IN_DAY) * ALL_DAY_HEIGHT_RATIO) /
      MINUTES_IN_DAY
    }%`};
  }
`;

const TimeCol = styled.div`
  border: ${rem(0.5)} solid ${({ theme }) => theme.borderColor};
  display: flex;
  flex-direction: column;
  width: ${rem(68)};
  border-top: none;
`;

const HeaderWrapper = styled.div`
  display: flex;
`;
const CalendarWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  /* overflow-x: auto; */
`;

const EventsContainer = styled.div`
  height: 100%;
  padding: ${rem(5)};
`;

const Events = styled.div`
  position: relative;
  height: 100%;
`;

const StyledEvent = styled(Event)`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  width: ${({ width }) => width};
  word-break: break-all;
  overflow: hidden;
  ${({ allDay }) => {
    if (!allDay) {
      return css`
        position: absolute;
        height: ${({ height }) => height};
      `;
    }
    return css`
      position: relative;
      margin: ${rem(3)} 0;
      &:last-child {
        margin-bottom: 0;
      }
    `;
  }};
`;

const HourTicks = styled.div`
  height: 100%;
  ${({ isFullScreen }) =>
    isFullScreen
      ? css`
          margin-top: ${rem(60)};
        `
      : css`
          margin-top: ${rem(73)};
        `};
`;

const HourTick = styled.div`
  font-size: ${rem(10)};
  text-align: right;
  ${({ idx }) => {
    return css`
      height: ${`${
        (100 *
          ((MINUTES_IN_DAY / HOURS_IN_DAY) *
            (idx === 0 ? ALL_DAY_HEIGHT_RATIO : 1))) /
        MINUTES_IN_DAY
      }`}%;
    `;
  }};
`;

const HourTickLabel = styled.div`
  position: relative;
  top: ${rem(-7.2)};
  margin-right: ${rem(10)};
  color: ${({ theme }) => theme.color.notImportant};
`;

const AllDayEvents = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin: -${rem(5)};
  padding: ${rem(5)};
  height: calc(
    ${rem(5)} +
      ${`${
        (100 * (MINUTES_IN_DAY / HOURS_IN_DAY) * ALL_DAY_HEIGHT_RATIO) /
        MINUTES_IN_DAY
      }%`}
  );
  overflow: auto;
`;

const AllDayEventsContainer = styled.div``;

// hour ticks array
const hours = [`Celý deň`];
for (let i = 0; i < 24; i++) {
  hours.push(`${i}:00`);
}

class WeekView extends Component {
  handleClickEvent = (id) => {
    this.props.dispatch(openEventDetail(id));
  };

  render() {
    const { events: dates, currentEventId, isFullScreen } = this.props;

    const eventsOverlap = (a, b) =>
      a.timeFrom < b.timeTo && a.timeTo > b.timeFrom;

    const weekTable = {};
    Object.keys(dates).forEach((date) => {
      weekTable[date] = [];
      const events = dates[date];
      const timetable = weekTable[date];
      events.forEach((eventToPlace) => {
        let level = 0;
        let placed = false;
        do {
          let skip = false;
          if (typeof timetable[level] === 'undefined') {
            timetable[level] = [];
          }
          for (const placedEvent of timetable[level]) {
            if (eventsOverlap(placedEvent, eventToPlace)) {
              level++;
              skip = true;
              break;
            }
          }
          if (!skip) {
            placed = true;
            timetable[level].push(eventToPlace);
          }
        } while (placed === false);
      });
    });

    const now = moment();
    const calendar = Object.keys(weekTable).map((dateStr) => {
      const levels = weekTable[dateStr];
      const date = moment(dateStr);
      const current = moment(date).isSame(now, 'day');
      return (
        <Column key={dateStr}>
          <TableHead currentDay={current} isFullScreen={isFullScreen}>
            <Date isFullScreen={isFullScreen}>{date.format('D')}</Date>
            <Day isFullScreen={isFullScreen}>{date.format('dddd')}</Day>
            {current && (
              <CurrentDay isFullScreen={isFullScreen}>{__('Dnes')}</CurrentDay>
            )}
          </TableHead>
          <ColumnContent>
            {hours.map((h, idx) => (
              <TableRow key={h} idx={idx} />
            ))}
            <EventsContainer>
              <Events>
                {levels.map((events, level) => {
                  const width = 100 / levels.length;
                  return events.reduce((acc, event) => {
                    if (!event.allDayEvent) {
                      return [
                        ...acc,
                        <StyledEvent
                          selected={currentEventId === event._id}
                          onClick={() => this.handleClickEvent(event._id)}
                          key={`${level.toString()}-${event._id}-${
                            event.timeFrom
                          }`}
                          level={level}
                          top={`${
                            (event.timeFrom * 100) / MINUTES_IN_DAY +
                            (100 *
                              (MINUTES_IN_DAY / HOURS_IN_DAY) *
                              ALL_DAY_HEIGHT_RATIO) /
                              MINUTES_IN_DAY
                          }%`}
                          left={`${level * width}%`}
                          height={`${(event.duration * 100) / MINUTES_IN_DAY}%`}
                          width={`calc(${width}% - ${rem(2)})`}
                          title={event.title}
                          color={event.color}
                        />,
                      ];
                    }
                    return acc;
                  }, []);
                })}
              </Events>
              <AllDayEvents>
                <AllDayEventsContainer>
                  {levels.map((events, level) => {
                    const width = 100 / levels.length;
                    return events.reduce((acc, event) => {
                      if (event.allDayEvent) {
                        return [
                          ...acc,
                          <StyledEvent
                            selected={currentEventId === event._id}
                            allDay
                            onClick={() => this.handleClickEvent(event._id)}
                            key={`${level.toString()}-${event._id}-${
                              event.timeFrom
                            }`}
                            level={level}
                            top={`${(event.timeFrom * 100) / MINUTES_IN_DAY}%`}
                            left={`${level * width}%`}
                            height={`${
                              (event.duration * 100) / MINUTES_IN_DAY
                            }%`}
                            width={`calc(${width}% - ${rem(2)})`}
                            title={event.title}
                            color={event.color}
                          />,
                        ];
                      }
                      return acc;
                    }, []);
                  })}
                </AllDayEventsContainer>
              </AllDayEvents>
            </EventsContainer>
          </ColumnContent>
        </Column>
      );
    });

    return (
      <Wrapper>
        <HeaderWrapper />
        <CalendarWrapper>
          <TimeCol>
            <HourTicks isFullScreen={isFullScreen}>
              {hours.map((h, i) => (
                <HourTick idx={i} key={h}>
                  <HourTickLabel>{h}</HourTickLabel>
                </HourTick>
              ))}
            </HourTicks>
          </TimeCol>
          <Content>{calendar}</Content>
        </CalendarWrapper>
      </Wrapper>
    );
  }
}

WeekView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  events: DatesType.isRequired,
  currentEventId: PropTypes.string,
  isFullScreen: PropTypes.bool.isRequired,
};

WeekView.defaultProps = {
  currentEventId: null,
};

const mapStateToProps = (state) => ({
  currentEventId: currentEventIdSelector(state),
});

export default connect(mapStateToProps)(WeekView);
