import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
`;

const LoaderBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${rem(3)};
  z-index: 20;
  overflow: hidden;
`;

const Bar = styled.div`
  transition: ${({ proceed }) => proceed && 'width 10s'};
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  transition: opacity 0.4s;
  background: ${({ theme }) => theme.color.primary};
`;

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proceed: false,
      progress: 0,
      hidden: true,
    };
    this.hideTimeout = null;
    this.proceedTimeout = null;
    this.finishedTimeout = null;

    this.barEl = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading < 0) {
      return;
    }
    if (nextProps.loading !== this.props.loading) {
      window.clearTimeout(this.hideTimeout);
      window.clearTimeout(this.proceedTimeout);
      window.clearTimeout(this.finishedTimeout);
    }
    if (nextProps.loading > 0) {
      this.setState({ hidden: false });
    }
    if (this.props.loading > 0 && nextProps.loading === 0) {
      this.setState(
        { progress: 500, proceed: false },
        this.startFinishedTimeout,
      );
    } else if (this.props.loading === 0 && nextProps.loading > 0) {
      this.setState(
        {
          progress: Math.floor(Math.random() * (60 - 40)) + 40,
          proceed: false,
        },
        this.startProceedTimeout,
      );
    } else if (nextProps.loading > this.props.loading) {
      const currentProgress =
        (this.barEl.scrollWidth / this.barEl.parentElement.scrollWidth) * 100;
      const delta = (currentProgress * 20) / 100;
      this.setState(
        { progress: currentProgress - delta, proceed: false },
        this.startProceedTimeout,
      );
    } else if (nextProps.loading < this.props.loading) {
      const currentProgress =
        (this.barEl.scrollWidth / this.barEl.parentElement.scrollWidth) * 100;
      const delta = ((100 - currentProgress) * 20) / 100;
      this.setState(
        { progress: currentProgress + delta, proceed: false },
        this.startProceedTimeout,
      );
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.hideTimeout);
    window.clearTimeout(this.finishedTimeout);
    window.clearTimeout(this.proceedTimeout);
  }

  startFinishedTimeout() {
    this.finishedTimeout = window.setTimeout(() => {
      this.setState({ hidden: true });
      setTimeout(() => {
        this.setState({ progress: 0 });
      }, 400); // After hide animation
    }, 1000); // Hide after 1 second
  }

  startProceedTimeout() {
    this.proceedTimeout = window.setTimeout(() => {
      this.setState({ progress: 100, proceed: true });
    }, 400); // 400ms as in CSS
  }

  render() {
    const { hidden, proceed, progress } = this.state;
    return (
      <LoaderWrapper>
        <LoaderBar>
          <Bar
            hidden={hidden}
            progress={progress}
            ref={(el) => {
              this.barEl = el;
            }}
            proceed={proceed}
          />
        </LoaderBar>
      </LoaderWrapper>
    );
  }
}

Loader.defaultProps = {
  loading: 0,
};

Loader.propTypes = {
  loading: PropTypes.number,
};

export default Loader;
