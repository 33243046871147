import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { createGlobalStyle, withTheme } from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  padding: 0;
  transition: all 0.4s;
  z-index: 7;
  right: ${({ opened }) => (opened ? '0' : '-100%')};
  overflow: auto;
  ${({ theme }) => theme.media.sm`
    box-shadow: -5px 0px 10px #888;
    width: ${rem(678)};
    padding: ${rem(30)};
  `};
`;

const bodyOverflowHiddenClass = 'sidebar-opened';

const GlobalStyle = createGlobalStyle`
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
  }
`;

const DetailSidebar = ({ children, opened }) => {
  React.useEffect(() => {
    if (opened) {
      document.body.classList.add(bodyOverflowHiddenClass);
    } else {
      document.body.classList.remove(bodyOverflowHiddenClass);
    }
  }, [opened]);

  return (
    <>
      <GlobalStyle />
      <Wrapper opened={opened}>{children}</Wrapper>
    </>
  );
};

DetailSidebar.propTypes = {
  children: PropTypes.node,
  opened: PropTypes.bool.isRequired,
};

DetailSidebar.defaultProps = {
  children: null,
};

export default withTheme(DetailSidebar);
